import { getDoc, DocumentReference, Timestamp } from '../models/dalaccess';

export type AfterSessionType = {
  endTime: Timestamp;
  showTime: Timestamp;
  skipped: boolean;
  answers: {
    concentration: number;
    equanimity: number;
    sensory_clarity: number;
  };
  id?: string;
};

export const getAfterSessionsWithRef = async (ref: DocumentReference): Promise<AfterSessionType | null> => {
  const afterSession = await getDoc(ref);

  if (!afterSession.exists()) {
    return null;
  }
  return {
    ...(afterSession.data() as AfterSessionType),
    id: afterSession.id,
  };
};
