// *******************************************************
// Incentives Screen
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect } from 'react';
import { useAppContext } from '../../contexts/appContext';
import BranchesList from '../../components/BranchesList';
import RespirationDataList from 'src/components/RespirationDataList';

// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------

const ScienceLoversPage = () => {
  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Science Lovers',
        path: '/science',
      },
    ]);
  }, [setBreadcrumbPaths]);

  return (
    <>
      <div className='sm:flex-auto mb-5'>
        <h1 className='text-xl font-semibold text-gray-900'>Science Data</h1>
      </div>
      <h3 className='text-l font-semibold text-gray-900'>New Respiration Data</h3>

      <RespirationDataList />
      <h3 className='text-l font-semibold text-gray-900'>Older Respiration Data</h3>
      <BranchesList />
    </>
  );
};

export default ScienceLoversPage;
