// *******************************************************
// RespirationDataList
// -------------------------------------------------------
// This is a list of respiration data
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// *******************************************
// Component Imports
// -------------------------------------------
import { ChevronDownIcon } from '@heroicons/react/solid';

// *******************************************
// Hooks Import
// -------------------------------------------
import { useAppContext } from '../contexts/appContext';

// *******************************************
// Action Imports
// -------------------------------------------
import { getBlob, getMetadata, StorageReference } from 'firebase/storage';
import { getUserById } from '../collections/user';
import { getAccountById } from '../collections/account';
import { getStorageRef } from '../firebase/utils';
import { getAllRespirationData } from '../collections/respirationData';
import { getSessionById } from '../collections/session';

import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { dieIfNullOrUndef } from '../utility/GeneralUtilities';
import SearchBar from './SearchBar';
import { toast } from 'react-toastify';
import { EccDataTs, RespirationDataPointsTs, BreakPointsTs } from '../modeltypes/respirationData';
import { BPM_DATA_TS } from '../modeltypes/session';

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
type RespirationListType = {
  id: string;
  breakPoints: BreakPointsTs;
  breathRate: number;
  brmp?: number;
  chartType: 'audio' | 'hexoskin' | 'accelerometer' | 'gyroscope';
  createdAt: Date;
  date: Date;
  data: RespirationDataPointsTs[];
  userId: string;
  zoneMinutes: number;
  respirationChange: number;
  unit: 'bpm' | '';
  description: string;
  eccData?: EccDataTs;
  sessionId?: string;
  userEmail: string;
  userName: string;
  lesson: string;
  audioLength: number | string;
  nameOfSession?: string;
  processedRespirationData?: BPM_DATA_TS[];
  sessionStart?: Date;
  audioFile?: string;
  audioRef?: StorageReference | null | undefined;
};

type RespirationListTypeWithoutNullFields = Omit<RespirationListType, 'audioUrl' | 'textUrl' | 'hexoDataUrl'>;

const RespirationDataList = () => {
  const navigate = useNavigate();

  const { searchTerm } = useAppContext();
  const [respirationData, setRespirationData] = useState<RespirationListType[]>();
  const [sortFilter, setSortFilter] = useState<{
    key: keyof RespirationListTypeWithoutNullFields;
    order: 'asc' | 'desc';
  }>();
  const [isLoading, setIsLoading] = useState(true);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!respirationData?.length) return [];
    return respirationData
      .filter((item) => {
        const matchEmail = item.userEmail && item.userEmail.match(regex);
        const matchName = item.userName && item.userName.match(regex);
        const matchLesson = item.lesson && item.lesson.match(regex);
        return matchName || matchEmail || matchLesson;
      })
      .sort((a, b) => {
        if (!sortFilter || sortFilter.key === 'audioLength') {
          return 0;
        }
        if (sortFilter.key === 'createdAt') {
          if (sortFilter.order === 'desc') {
            return a.createdAt.getTime() - b.createdAt.getTime();
          }
          if (sortFilter.order === 'asc') {
            return b.createdAt.getTime() - a.createdAt.getTime();
          }
        }
        // if (['userName','userEmail','unit'].includes(sortFilter.key)) {
        if (
          sortFilter.key === 'userEmail' ||
          sortFilter.key === 'userName' ||
          sortFilter.key === 'unit' ||
          sortFilter.key === 'lesson'
        ) {
          if (sortFilter.order === 'desc') {
            return a[sortFilter.key].toString().localeCompare(b[sortFilter.key].toString());
          }
          if (sortFilter.order === 'asc') {
            return b[sortFilter.key].toString().localeCompare(a[sortFilter.key].toString());
          }
        } else if (sortFilter.key && typeof a === 'object' && typeof b === 'object') {
          // else if (sortFilter.key && typeof a === 'object' && typeof b === 'object') {
          //   if (sortFilter.order === 'desc') {
          //     return a[sortFilter.key].toString().localeCompare(b[sortFilter.key].toString());
          //   }
          //   if (sortFilter.order === 'asc') {
          //     return b[sortFilter.key].toString().localeCompare(a[sortFilter.key].toString());
          //   }
        }
        return 0;
      });
  }, [searchTerm, respirationData, sortFilter]);

  useEffect(() => {
    const prepareRespirationData = async () => {
      const respirationDataArray = await getAllRespirationData();
      const tempArray = respirationDataArray.map(async (respirationObject) => {
        // const textRef = respirationObject.dataUrl ? getStorageRef(respirationObject.dataUrl) : null;
        // const hexoRef = respirationObject.hexoDataUrl ? getStorageRef(respirationObject.hexoDataUrl) : null;

        const user = await getUserById(respirationObject.userId);
        const account = await getAccountById(respirationObject.userId);
        const session = await getSessionById(respirationObject.sessionId);
        const audioRef = session?.audioFile ? getStorageRef(session?.audioFile) : null;
        const audioMeta = audioRef
          ? await getMetadata(audioRef).catch(() => console.error('its not in this storage'))
          : null;
        return Promise.resolve<RespirationListType>({
          id: respirationObject.id,
          audioLength: audioMeta?.customMetadata?.audioLength
            ? `${(Number.parseInt(audioMeta.customMetadata.audioLength, 10) / 60000).toFixed(0)} min ${(
                (Number.parseInt(audioMeta.customMetadata.audioLength, 10) / 1000) %
                60
              ).toFixed(0)} secs`
            : 'No audio',
          breakPoints: respirationObject.breakPoints,
          breathRate: respirationObject.breathRate,
          brmp: respirationObject.brmp,
          chartType: respirationObject.chartType,
          data: respirationObject.data,
          zoneMinutes: respirationObject.zoneMinutes,
          description: respirationObject.description,
          unit: respirationObject.unit,
          userId: respirationObject.userId,
          respirationChange: respirationObject.respirationChange,
          eccData: respirationObject.eccData,
          date: respirationObject.date,
          sessionId: respirationObject?.sessionId || 'none',
          lesson: `${respirationObject.unit}`,
          createdAt: dieIfNullOrUndef(respirationObject.createdAt?.toDate()),
          userEmail: account?.email || '',
          userName: `${user?.firstName} ${user?.lastName}`,
          nameOfSession: session?.nameOfSession || '',
          processedRespirationData: session?.processedRespirationData || [],
          sessionStart: session?.sessionStart?.toDate(),
          audioFile: session?.audioFile,
          audioRef: audioRef,
        });
      });

      return Promise.all(tempArray);
    };

    prepareRespirationData()
      .then((array) => {
        setRespirationData(array);
        setIsLoading(false);
      })
      .catch((e) => console.error(e));
  }, []);

  const downloadFile = async (path: StorageReference | null | undefined) => {
    if (path) {
      const blob = await getBlob(path);
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = path.parent?.name || path.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const sortRequest = (fieldName: keyof RespirationListTypeWithoutNullFields) => {
    let order: 'asc' | 'desc' = 'asc';
    if (sortFilter && sortFilter.key === fieldName && sortFilter.order === 'asc') {
      order = 'desc';
    }
    setSortFilter({
      key: fieldName,
      order: order,
    });
  };

  const toastWrap = (downloadFunction: Promise<void>) => {
    toast.promise(downloadFunction, {
      pending: `Preparing file...`,
      error: "Can't do it now, try again.",
      success: `File is being downloaded!`,
    });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                        onClick={() => sortRequest('createdAt')}
                      >
                        <p className='group inline-flex'>
                          Created At
                          <span
                            className={`${sortFilter?.key !== 'createdAt' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                        onClick={() => sortRequest('userEmail')}
                      >
                        <p className='group inline-flex'>
                          User Email
                          <span
                            className={`${sortFilter?.key !== 'userEmail' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                        onClick={() => sortRequest('userName')}
                      >
                        <p className='group inline-flex'>
                          User Name
                          <span
                            className={`${sortFilter?.key !== 'userName' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                        onClick={() => sortRequest('lesson')}
                      >
                        <p className='group inline-flex'>
                          Lesson
                          <span
                            className={`${sortFilter?.key !== 'lesson' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>{' '}
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                        onClick={() => sortRequest('audioFile')}
                      >
                        <p className='group inline-flex'>
                          Audio File
                          <span
                            className={`${sortFilter?.key !== 'audioFile' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        SessionId
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Data
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Respiration Change
                      </th>
                      <th scope='col' colSpan={3} className='text-center'>
                        <p>Download files</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems?.map((respiration) => (
                      <tr key={respiration.id} className='hover:bg-stone-200'>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{format(respiration.createdAt, 'dd.MM.yyyy, HH:mm')}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{respiration.userEmail}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>{respiration.userName}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {respiration.nameOfSession}
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              respiration.audioFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              if (respiration.audioFile) {
                                toastWrap(downloadFile(respiration.audioRef));
                              }
                            }}
                          >
                            {respiration.audioFile
                              ? `Audio (${respiration?.processedRespirationData?.length || '0'} secs)`
                              : '-'}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              respiration.audioFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                          >
                            {respiration?.sessionId}
                          </div>
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              respiration.audioFile ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              navigate(`/rawData/${respiration.id}/${respiration.sessionId}`);
                            }}
                          >
                            {respiration?.chartType}
                          </div>
                        </td>

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>{}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>
                            {typeof respiration?.respirationChange === 'number'
                              ? Math.floor(respiration?.respirationChange)
                              : 'no data'}
                          </div>
                        </td>

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {respiration?.audioLength}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(RespirationDataList);
