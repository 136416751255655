// *******************************************************
// HomeScreen
// -------------------------------------------------------
// This is a Screen for showing list all Statistics
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useAppContext } from '../../contexts/appContext';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Spinner from '../../components/Spinner';
import { getAllAnalytics, getAnalyticsByQuery } from '../../collections/analytics';
import { orderBy, where } from '../../models/dalaccess';
import { limit } from 'firebase/firestore';
import NewStatBox from './NewStatBox';
import { addMonths, format, isFuture, isSameDay, isSameMonth, lastDayOfMonth } from 'date-fns';

// import { inAppTitles, todayTitles } from './statUtils';
import { getAnalyticsCalculations, getAnalyticsDifferenceBetweenDates } from '../../firebase/firebaseConfig';
import { AnalyticsType, ChartDataType, ChartType, DailyDataType, TotalDataType } from 'src/modeltypes/analytics';
import BarChart from 'src/components/BarChart';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import colors from 'src/assets/colors';
import Skeleton from 'react-loading-skeleton';
import MonthonMonthAnalysis from './MonthonMonthAnalsis';
import WeekOnWeekAnalysis from './WeekOnWeekAnalysis';
import QuarteronQuarterAnalysis from './QuarteronQuarterAnalsis';
import HomeworkAnalysis from './HomeworkAnalysis';
import CommunicationAnalysis from './CommunicationAnalysis';
// *******************************************
// Component Imports
// -------------------------------------------

// *******************************************
// Hooks Import
// -------------------------------------------

// *******************************************
// Action Imports
// -------------------------------------------

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
interface NavTs {
  name?: string;
  page: string;
  href?: string;
  access?: string[] | undefined;
  disabled?: boolean;
  month?: number;
  year?: number;
  date?: Date;
}

export type MonthSelector = {
  value: string;
  label: string;
};

export const ANALYSIS_TYPES: { [name: string]: string } = {
  ALL: 'ALL',
  B2C: 'B2C',
  COMPANYS: 'COMPANYS',
  COHORTS: 'COHORTS',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
};

export const AnalysisTypeSelector = Object.keys(ANALYSIS_TYPES).map((t) => {
  return {
    label: ANALYSIS_TYPES[t],
    value: t,
  };
});

const MONTHS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const YEARS = [2023, 2024, 2025];

// *******************************************
// Constants
// -------------------------------------------
const navigation: NavTs[] = [
  {
    name: "Today's Users",
    page: 'allUsersDaily',
    href: '#',
  },

  {
    name: 'Total Users',
    page: 'allUsersTotal',
    href: '#',
  },
  {
    name: "Today's Cohort Users",
    page: 'cohortDaily',
    href: '#',
  },

  {
    name: 'Total Cohort Users',
    page: 'cohortTotal',
    href: '#',
  },
  {
    name: "Today's Company Users",
    page: 'companyDaily',
    href: '#',
  },

  {
    name: 'Total Company Users',
    page: 'companyTotal',
    href: '#',
  },
  {
    name: "Today's B2C Users",
    page: 'b2cDaily',
    href: '#',
  },
  {
    name: 'Total B2C Users',
    page: 'b2cTotal',
    href: '#',
  },
  {
    name: "Today's Subscription Users",
    page: 'subscriptionsDaily',
    href: '#',
  },
  {
    name: 'Total Subscription Users',
    page: 'subscriptionsTotal',
    href: '#',
  },
  {
    name: 'Monthly Breakdown',
    page: 'monthlyBreakdown',
    href: '#',
  },
  {
    name: 'Month On Month Analysis',
    page: 'monthOnMonth',
    href: '#',
  },
  {
    name: 'Quater On Quater Analysis',
    page: 'quaterOnQuater',
    href: '#',
  },

  {
    name: 'Week on Week Analysis',
    page: 'weekOnWeek',
    href: '#',
  },
  {
    name: 'Homework Analysis',
    page: 'homeworkAnalysis',
    href: '#',
  },

  {
    name: 'Communication Analysis',
    page: 'communicationAnalysis',
    href: '#',
  },
];

const monthlyBreakdownNavigation: NavTs[] = [];

YEARS.map((year) => {
  return MONTHS.map((monthIndex) => {
    const thisDate = new Date(year, monthIndex);
    const month = format(thisDate, 'MMMM');

    if (isFuture(thisDate)) {
      return null;
    }

    const thisReturn = {
      name: `${month}-${year}`,
      page: `${month}-${year}`,
      href: '#',
      month: monthIndex,
      year,
      date: thisDate,
    };

    return monthlyBreakdownNavigation.push(thisReturn);
  });
});

console.log('monthlyBreakdownNavigation: ', monthlyBreakdownNavigation);

const HomePage = () => {
  const { setBreadcrumbPaths } = useAppContext();
  const getLastTodaysAnalytics = async () => {
    const analytics = await getAnalyticsByQuery(where('type', '==', 'TODAY'), orderBy('createdAt', 'desc'), limit(1));
    return analytics.length ? analytics[0] : null;
  };
  const getLastTotalAnalytics = async () => {
    const analytics = await getAnalyticsByQuery(where('type', '==', 'TOTAL'), orderBy('createdAt', 'desc'), limit(1));
    return analytics.length ? analytics[0] : null;
  };

  const {
    data: todaysAnalytics,
    isLoading: isFetchingAnalytics,
    refetch: refetchAnalytics,
  } = useQuery({
    queryFn: getLastTodaysAnalytics,
    queryKey: ['today', 'analytics', 'data'],
  });

  const {
    data: totalAnalytics,
    isLoading: isFetchingTotalAnalytics,
    refetch: refetchTotalAnalytics,
  } = useQuery({
    queryFn: getLastTotalAnalytics,
    queryKey: ['total', 'analytics', 'data'],
  });
  const { isPending: isRecalculating, mutate: runCalculations } = useMutation({
    mutationFn: async () => {
      await getAnalyticsCalculations();
    },
    mutationKey: ['analytics', 'sync'],
  });

  // For Any Data Analysis
  const {
    isPending: isCalculating,
    mutate: calculateDifference,
    data: differenceData,
  } = useMutation({
    mutationFn: getAnalyticsDifferenceBetweenDates,
    mutationKey: ['analytics', 'differenceCalculation'],
  });

  const difference = differenceData?.data;

  // For Any Data Analysis
  const {
    data: allAnalytics,
    isPending: isGettingAllAnalytics,
    refetch: refetchAllAnalytics,
  } = useQuery({
    queryFn: getAllAnalytics,
    queryKey: ['total', 'analytics', 'data', 'all'],
  });

  console.log('allAnalytics', allAnalytics);
  console.log('isGettingAllAnalytics', isGettingAllAnalytics);
  console.log('refetchAllAnalytics', refetchAllAnalytics);

  // states
  const [currentTab, setCurrentTab] = useState('allUsersDaily');
  const [currentMonthType, setCurrentMonthType] = useState('SUBSCRIPTIONS');
  const [currentMonttoMonthTab, setCurrentMonthToMonthTab] = useState<string>(
    monthlyBreakdownNavigation?.[monthlyBreakdownNavigation?.length]?.page,
  );

  // For the Month to Month
  const [startDateTotalValues, setStartDateTotalValues] = useState<any | null>(null);
  const [endDateTotalValues, setEndDateTotalValues] = useState<any | null>(null);
  const [allDailyAnalyticsForThisMonth, setAllDailyAnalyticsForThisMonth] = useState<any[] | null>(null);
  const [allTotalAnalyticsForThisMonth, setAllTotalAnalyticsForThisMonth] = useState<any[] | null>(null);

  const monthlyBreakdownTotalValues = useMemo(() => {
    if (!startDateTotalValues || !endDateTotalValues) return null;

    let startAnalytics: any | null = null;
    let endAnalytics: any | null = null;

    if (currentMonthType === 'ALL') {
      startAnalytics = startDateTotalValues.allUsersTotal;
      endAnalytics = endDateTotalValues.allUsersTotal;
    }
    if (currentMonthType === 'B2C') {
      startAnalytics = startDateTotalValues.b2cTotal;
      endAnalytics = endDateTotalValues.b2cTotal;
    }
    if (currentMonthType === 'COMPANYS') {
      startAnalytics = startDateTotalValues.companyTotal;
      endAnalytics = endDateTotalValues.companyTotal;
    }

    if (currentMonthType === 'COHORTS') {
      startAnalytics = startDateTotalValues.cohortTotal;
      endAnalytics = endDateTotalValues.cohortTotal;
    }
    if (currentMonthType === 'SUBSCRIPTIONS') {
      startAnalytics = startDateTotalValues.subscriptionsTotal;
      endAnalytics = endDateTotalValues.subscriptionsTotal;
    }

    const age = endAnalytics.age
      .map((ageItem: ChartType) => {
        const startAgeItem = startAnalytics?.age.find((age: ChartType) => age.label === ageItem.label);
        return { label: ageItem.label, userCount: ageItem.userCount - (startAgeItem?.userCount || 0) };
      })
      .filter((ageItem: ChartType) => ageItem.userCount);
    const subscriptions = endAnalytics.subscriptions
      .map((subscriptionItem: ChartType) => {
        const startSubscriptionItem = startAnalytics?.subscriptions.find(
          (sSubscription: ChartType) => sSubscription.label === subscriptionItem.label,
        );
        return {
          label: subscriptionItem.label,
          userCount: subscriptionItem.userCount - (startSubscriptionItem?.userCount || 0),
        };
      })
      .filter((ageItem: ChartType) => ageItem.userCount);
    const wearables = endAnalytics.wearables
      .map((wearableItem: ChartType) => {
        const startWearableItem = startAnalytics?.wearables?.find(
          (sWearable: ChartType) => sWearable.label === wearableItem.label,
        );
        return { label: wearableItem.label, userCount: wearableItem.userCount - (startWearableItem?.userCount || 0) };
      })
      .filter((wearableItem: ChartType) => wearableItem.userCount);
    const wants = endAnalytics.wants
      .map((wantItem: ChartType) => {
        const startWantItem = startAnalytics?.wants?.find((sWant: ChartType) => sWant.label === wantItem.label);
        return { label: wantItem.label, userCount: wantItem.userCount - (startWantItem?.userCount || 0) };
      })
      .filter((wantItem: ChartType) => wantItem.userCount);
    const goals = endAnalytics.goals
      .map((goalItem: ChartType) => {
        const startGoalItem = startAnalytics?.goals?.find((sGoal: ChartType) => sGoal.label === goalItem.label);
        return { label: goalItem.label, userCount: goalItem.userCount - (startGoalItem?.userCount || 0) };
      })
      .filter((goalItem: ChartType) => goalItem.userCount);

    const introProgress = endAnalytics.introProgress?.map((progressItem: ChartType) => {
      const startIntroProgress = startAnalytics?.introProgress?.find(
        (sProgressItem: ChartType) => sProgressItem.label === progressItem.label,
      );
      return { label: progressItem.label, userCount: progressItem.userCount - (startIntroProgress?.userCount || 0) };
    });
    const postIntroProgress = endAnalytics.postIntroSeriesProgress?.map((progressItem: ChartType) => {
      const startPostIntroProgress = startAnalytics?.postIntroSeriesProgress?.find(
        (sProgressItem: ChartType) => sProgressItem.label === progressItem.label,
      );
      return {
        label: progressItem.label,
        userCount: progressItem.userCount - (startPostIntroProgress?.userCount || 0),
      };
    });

    console.log('endAnalytics.subscriptionAccounts : ', endAnalytics.subscriptionAccounts);
    console.log('startAnalytics.subscriptionAccounts : ', startAnalytics.subscriptionAccounts);

    return {
      sessions: endAnalytics.sessions - startAnalytics.sessions,
      totalUsers: endAnalytics.totalUsers - startAnalytics.totalUsers,
      lessonsCompleted: endAnalytics.lessonsCompleted - startAnalytics.lessonsCompleted,
      // averageLessonsPerUser: endAnalytics.averageLessonsPerUser - startAnalytics.averageLessonsPerUser,
      // averageTimeInLessonsPerUser: endAnalytics.averageTimeInLessonsPerUser - startAnalytics.averageTimeInLessonsPerUser,
      usersWhoDidntFinishOnboarding:
        endAnalytics.usersWhoDidntFinishOnboarding - startAnalytics.usersWhoDidntFinishOnboarding,
      age: age,
      introProgress: introProgress,
      notificationsSent: endAnalytics.notificationsSent.length - startAnalytics.notificationsSent.length,
      emailsSent: endAnalytics.emailsSent.length - startAnalytics.emailsSent.length,
      postIntroSeriesProgress: postIntroProgress,
      wants: wants,
      goals: goals,
      wearables: wearables,
      subscriptionAccounts: endAnalytics.subscriptionAccounts - startAnalytics.subscriptionAccounts,
      subscriptions: subscriptions,
      expiredSubscriptionAccounts:
        endAnalytics.expiredSubscriptionAccounts - startAnalytics.expiredSubscriptionAccounts,
    };
  }, [currentMonttoMonthTab, isGettingAllAnalytics, currentMonthType]);

  const monthlyBreakdownDailyValues = useMemo(() => {
    if (!allDailyAnalyticsForThisMonth) return null;

    let dailyAnalytics: any | null = null;

    if (currentMonthType === 'ALL') {
      dailyAnalytics = allDailyAnalyticsForThisMonth.map((m) => m.allUsersDaily);
    }
    if (currentMonthType === 'B2C') {
      dailyAnalytics = allDailyAnalyticsForThisMonth.map((m) => m.b2cDaily);
    }
    if (currentMonthType === 'COMPANYS') {
      dailyAnalytics = allDailyAnalyticsForThisMonth.map((m) => m.companyDaily);
    }

    if (currentMonthType === 'COHORTS') {
      dailyAnalytics = allDailyAnalyticsForThisMonth.map((m) => m.cohortDaily);
    }
    if (currentMonthType === 'SUBSCRIPTIONS') {
      dailyAnalytics = allDailyAnalyticsForThisMonth.map((m) => m.subscriptionsDaily);
    }

    const newUsers = dailyAnalytics.reduce((sum: number, { newUsers }: { newUsers: number }) => sum + newUsers, 0);
    const usersWhoDidntFinishOnboarding = dailyAnalytics.reduce(
      (sum: number, { usersWhoDidntFinishOnboarding }: { usersWhoDidntFinishOnboarding: number }) =>
        sum + usersWhoDidntFinishOnboarding,
      0,
    );
    const expiredSubscriptions = dailyAnalytics.reduce(
      (sum: number, { expiredSubscriptions }: { expiredSubscriptions: number }) => sum + expiredSubscriptions,
      0,
    );
    const activeUsers = dailyAnalytics.reduce(
      (sum: number, { activeUsers }: { activeUsers: number }) => sum + activeUsers,
      0,
    );
    const usersWhoDidntFinishOnboardingDailySum = dailyAnalytics.reduce(
      (sum: number, { usersWhoDidntFinishOnboarding }: { usersWhoDidntFinishOnboarding: number }) =>
        sum + usersWhoDidntFinishOnboarding,
      0,
    );

    return {
      newUsers,
      usersWhoDidntFinishOnboarding,
      expiredSubscriptions,
      activeUsers,
      usersWhoDidntFinishOnboardingDailySum,
    };
  }, [currentMonttoMonthTab, isGettingAllAnalytics, currentMonthType, allDailyAnalyticsForThisMonth]);

  const monthlyBreakdownTotalAverageValues = useMemo(() => {
    if (!allTotalAnalyticsForThisMonth) return null;

    console.log('allTotalAnalyticsForThisMonth', allTotalAnalyticsForThisMonth);

    let totalAnalytics: any | null = null;

    if (currentMonthType === 'ALL') {
      totalAnalytics = allTotalAnalyticsForThisMonth.map((m) => m.allUsersTotal);
    }
    if (currentMonthType === 'B2C') {
      totalAnalytics = allTotalAnalyticsForThisMonth.map((m) => m.b2cTotal);
    }
    if (currentMonthType === 'COMPANYS') {
      totalAnalytics = allTotalAnalyticsForThisMonth.map((m) => m.companyTotal);
    }

    if (currentMonthType === 'COHORTS') {
      totalAnalytics = allTotalAnalyticsForThisMonth.map((m) => m.cohortTotal);
    }
    if (currentMonthType === 'SUBSCRIPTIONS') {
      totalAnalytics = allTotalAnalyticsForThisMonth.map((m) => m.subscriptionsTotal);
    }

    console.log('totalAnalytics: ', totalAnalytics);

    const totalAveragelessonsPerUser = totalAnalytics.reduce(
      (sum: number, { averageLessonsPerUser }: { averageLessonsPerUser: number }) =>
        averageLessonsPerUser ? sum + averageLessonsPerUser : sum,
      0,
    );
    const totalTimeInLessonsPerUser = totalAnalytics.reduce(
      (sum: number, { averageTimeInLessonsPerUser }: { averageTimeInLessonsPerUser: number }) =>
        averageTimeInLessonsPerUser ? sum + averageTimeInLessonsPerUser : sum,
      0,
    );

    const averageLessonsPerUser = totalAveragelessonsPerUser / totalAnalytics.length;
    const averageTimeInLessonsPerUser = totalTimeInLessonsPerUser / totalAnalytics.length;

    console.log('totalAveragelessonsPerUser: ', totalAveragelessonsPerUser);
    console.log('totalTimeInLessonsPerUser: ', totalTimeInLessonsPerUser);

    console.log('averageLessonsPerUser: ', averageLessonsPerUser);
    console.log('averageTimeInLessonsPerUser: ', averageTimeInLessonsPerUser);

    return {
      averageLessonsPerUser,
      averageTimeInLessonsPerUser,
    };
  }, [currentMonttoMonthTab, isGettingAllAnalytics, currentMonthType, allTotalAnalyticsForThisMonth]);

  console.log('monthlyBreakdownDailyValues: ', monthlyBreakdownDailyValues);
  console.log('monthlyBreakdownTotalValues: ', monthlyBreakdownTotalValues);
  console.log('monthlyBreakdownTotalAverageValues: ', monthlyBreakdownTotalAverageValues);

  useEffect(() => {
    if (currentMonttoMonthTab && allAnalytics?.length) {
      console.log('currentMonttoMonthTab:', currentMonttoMonthTab);
      const getThisDate = monthlyBreakdownNavigation.find((nav) => nav.page === currentMonttoMonthTab);
      const currentMonth = getThisDate?.date || new Date();
      console.log('currentMonth: ', currentMonth);
      const lastDayOfCurrentMonth =
        lastDayOfMonth(currentMonth) > currentMonth ? currentMonth : lastDayOfMonth(currentMonth);
      const lastMonthEndofPreviousMonthDate = addMonths(lastDayOfCurrentMonth, -1);
      console.log('lastDayOfCurrentMonth', lastDayOfCurrentMonth);
      console.log('lastMonthEndofPreviousMonthDate', lastMonthEndofPreviousMonthDate);

      const thisStartDateAnalytics = allAnalytics.find(
        (analyticItem: AnalyticsType) =>
          analyticItem.type === 'TOTAL' && isSameDay(analyticItem.createdAt.toDate(), lastMonthEndofPreviousMonthDate),
      );
      const thisEndDateAnalytics = allAnalytics.find(
        (analyticItem: AnalyticsType) =>
          analyticItem.type === 'TOTAL' && isSameDay(analyticItem.createdAt.toDate(), lastDayOfCurrentMonth),
      );

      console.log('thisStartDateAnalytics', thisStartDateAnalytics);
      console.log('thisStartDateAnalytics', thisStartDateAnalytics?.createdAt.toDate());

      console.log('thisEndDateAnalytics', thisEndDateAnalytics);
      console.log('thisEndDateAnalytics', thisEndDateAnalytics?.createdAt.toDate());

      setStartDateTotalValues(thisStartDateAnalytics || null);
      setEndDateTotalValues(thisEndDateAnalytics || null);
      const thisAllDailyValues = allAnalytics.filter(
        (analyticItem: AnalyticsType) =>
          analyticItem.type === 'TODAY' && isSameMonth(analyticItem.createdAt.toDate(), currentMonth),
      );
      setAllDailyAnalyticsForThisMonth(thisAllDailyValues);

      const thisAllTotalValues = allAnalytics.filter(
        (analyticItem: AnalyticsType) =>
          analyticItem.type === 'TOTAL' && isSameMonth(analyticItem.createdAt.toDate(), currentMonth),
      );
      setAllTotalAnalyticsForThisMonth(thisAllTotalValues);
    }
  }, [currentMonttoMonthTab, isGettingAllAnalytics, currentMonthType]);

  console.log('startDateTotalValues', startDateTotalValues);
  console.log('endDateTotalValues', endDateTotalValues);
  console.log('allDailyAnalyticsForThisMonth', allDailyAnalyticsForThisMonth);
  console.log('monthlyBreakdownTotalValues', monthlyBreakdownTotalValues);

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {
    if (!isRecalculating) {
      Promise.all([refetchAnalytics(), refetchTotalAnalytics()]).catch((e) => console.error(e));
    }
  }, []);

  useEffect(() => {
    setBreadcrumbPaths([]);
  }, [setBreadcrumbPaths]);

  const currentAnalytics = { ...todaysAnalytics, ...totalAnalytics } as AnalyticsType;

  const isFetchingData = isFetchingAnalytics || isFetchingTotalAnalytics;

  const today = new Date();
  // const lastMonth = (new Date()).setDate(today.getDate() - 1)
  const [monthStartDate, setMonthStartDate] = useState(today);
  const [monthEndDate, setMonthEndDate] = useState<null | Date>(null);

  const chartDetails: { chart: string; title: string; xLabel: string; yLabel: string; color: string }[] = [
    { chart: 'age', title: 'Age', xLabel: 'Age of Uses', yLabel: 'User Count', color: colors.secondary },
    {
      chart: 'experienceActiveUsers',
      title: 'Experience of Active Users',
      xLabel: 'Experience',
      yLabel: 'User Count',
      color: colors.primary,
    },
    { chart: 'goalsActiveUsers', title: 'Goal of Active Users', xLabel: 'Goal', yLabel: 'User Count', color: 'yellow' },
    {
      chart: 'wantsActiveUsers',
      title: 'Wants of Active Users',
      xLabel: 'Wants',
      yLabel: 'User Count',
      color: colors.yellow,
    },
    {
      chart: 'devicesNewUsers',
      title: 'Experience of NEW Users',
      xLabel: 'Devices',
      yLabel: 'User Count',
      color: colors.secondary,
    },
    {
      chart: 'introProgress',
      title: 'Intro Progress Lessons',
      xLabel: 'Lesson',
      yLabel: 'User Count',
      color: colors.primary,
    },

    {
      chart: 'versions',
      title: 'App Version',
      xLabel: 'App Version',
      yLabel: 'User Count',
      color: colors.yellow,
    },

    {
      chart: 'retention',
      title: 'Retention of Users',
      xLabel: 'Day',
      yLabel: 'Percentage of Users',
      color: colors.secondary,
    },

    {
      chart: 'deviceActiveUsrs',
      title: 'Devices of Users Active Users',
      xLabel: 'Devices',
      yLabel: 'User Count',
      color: colors.primary,
    },

    {
      chart: 'wantsNewUsers',
      title: 'Wants of New Users',
      xLabel: 'Wants',
      yLabel: 'User Count',
      color: colors.yellow,
    },

    {
      chart: 'postIntroSeriesProgress',
      title: 'Post-intro Lessons Progress',
      xLabel: 'Lesson',
      yLabel: 'User Count',
      color: colors.mood,
    },

    {
      chart: 'experienceNewUsers',
      title: 'Devices of NEW Users',
      xLabel: 'Experience',
      yLabel: 'User Count',
      color: colors.focus,
    },
    {
      chart: 'goalsNewUsers',
      title: 'Goals of NEW Users',
      xLabel: 'Goals',
      yLabel: 'User Count',
      color: colors.primary,
    },
    {
      chart: 'wantssNewUsers',
      title: 'Wants of NEW Users',
      xLabel: 'Wants',
      yLabel: 'User Count',
      color: colors.secondary,
    },
  ];

  const _renderCharts = (data: ChartDataType[] | undefined) => {
    if (!data?.length) return null;

    return data?.map((dataItem: ChartDataType) => {
      const chartKey = Object?.keys(dataItem)?.[0];
      const thisChartData = Object?.values(dataItem)?.[0];

      if (thisChartData === undefined) {
        // little change here

        return (
          <div key={chartKey}>
            <p>{`chartKey : ${chartKey} with thisChartData = ${thisChartData}`}</p>
          </div>
        );
      }

      if (['emailsSent', 'notificationsSent'].includes(chartKey)) {
        return (
          <div key={chartKey}>
            <p>
              {chartKey}: {thisChartData?.length}
            </p>
          </div>
        );
      }

      if (thisChartData && typeof Array.isArray(thisChartData)) {
        const prepData = thisChartData?.map((data: any, index: number) => {
          if (['retention', 'retention30Days', 'retention60Days', 'retention90Days'].includes(chartKey))
            return { x: `Day ${index + 1}`, y: Math.floor(data) };
          return { x: `${data.label}`, y: data.userCount };
        });
        const thisChartDetails = chartDetails.find((x) => x.chart === chartKey);
        if (!prepData?.length) return null;
        return (
          <div key={chartKey}>
            <BarChart
              chartData={[
                {
                  data: prepData,
                  fill: thisChartDetails?.color || colors.primary,
                  name: chartKey,
                },
              ]}
              chartTitle={thisChartDetails?.title || chartKey}
              // tickValues={prepData}
              verticalLabel
              barWidth={10}
              domainPadding={10}
              tooltipsEnabled
              xLabel={thisChartDetails?.xLabel}
              yLabel={thisChartDetails?.yLabel}
            />
          </div>
        );
      } else {
        return <div key={chartKey}>{`${chartKey} is not showing anything`}</div>;
      }
    });
  };

  const _renderStats = ({
    title,
    subtitle,
    data,
  }: {
    title: string;
    subtitle: string;
    data: ChartDataType | undefined;
  }) => {
    if (data === undefined) {
      // little change here
      return (
        <div>
          <p>{`No salvation with thisData = ${data}`}</p>
        </div>
      );
    }

    if (data && typeof data === 'object') {
      return (
        <div className='flex flex-col flex-wrap'>
          <div className='flex-wrap gap-y-7 gap-x-2'>
            <NewStatBox title={title} subtitle={subtitle} data={data} isLoading={false} />
          </div>
        </div>
      );
    }
  };

  const getChartData = (keys: any, data: any) => {
    return keys
      .map((bojKey: string) => {
        const thisKey = bojKey as keyof DailyDataType | TotalDataType;
        const thisData = data ? data?.[`${thisKey}`] : null;
        if (Array.isArray(thisData) || bojKey === 'retention') {
          return {
            [thisKey as keyof DailyDataType]: thisData,
          };
        }
        if (['emailsSent', 'notificationsSent'].includes(bojKey)) {
          return {
            [thisKey as keyof DailyDataType]: null,
          };
        }
      })
      .filter((x: any) => !!x);
  };

  const getStatsData = (keys: any, data: any) => {
    const statsArray = keys
      .map((bojKey: string) => {
        const thisKey = bojKey as keyof DailyDataType | TotalDataType;
        const thisData = data ? data?.[`${thisKey}`] : null;
        if (typeof thisData === 'number') {
          return {
            [thisKey as keyof DailyDataType]: thisData,
          };
        }
        if (['emailsSent', 'notificationsSent'].includes(bojKey)) {
          return {
            [thisKey as keyof DailyDataType]: thisData?.length,
          };
        }
      })
      .filter((x: any) => !!x);
    return Object.assign({}, ...statsArray);
  };

  const getChartTitle = () => {
    switch (currentTab) {
      case 'allUsersDaily':
        return "Today's Statistics";
      case 'allUsersTotal':
        return 'All User Statistics';
      case 'cohortTotal':
        return 'Cohort Statistics';
      case 'cohortDaily':
        return 'Cohort Daily Statistics';
      case 'companyTotal':
        return 'Company Total Statistics';
      case 'companyDaily':
        return 'Company Daily Statistics';
      case 'b2cDaily':
        return 'B2C Daily User Statistics';
      case 'b2cTotal':
        return 'B2C Total User Statistics';
      case 'subscriptionsTotal':
        return 'subscriptionsTotal';
      case 'subscriptionsDaily':
        return 'subscriptionsDaily';

      default:
        return 'Unknown';
    }
  };

  const getChartSubtitle = () => {
    switch (currentTab) {
      case 'allUsersDaily':
        return 'Data for Users Today';
      case 'allUsersTotal':
        return 'Data for All Users (Companys B2C, B2B, Cohorts, etc.';
      case 'cohortTotal':
        return 'Data for All Cohorts';
      case 'cohortDaily':
        return "Data for Today's Cohorts";
      case 'companyTotal':
        return 'Data for All Companys';
      case 'companyDaily':
        return "Data for Today's Companys";
      case 'b2cDaily':
        return "Data for Today's B2C Customers";
      case 'b2cTotal':
        return 'Data for All B2C Customers';
      case 'subscriptionsTotal':
        return 'Data for Total Subscribers Customer';
      case 'subscriptionsDaily':
        return "Data for Today's Subscribers Customer";
      default:
        return 'allUsersDaily';
    }
  };

  const _renderContent = () => {
    if (currentTab === 'monthOnMonth') {
      return <MonthonMonthAnalysis analytics={allAnalytics} />;
    }

    if (currentTab === 'quaterOnQuater') {
      return <QuarteronQuarterAnalysis analytics={allAnalytics} />;
    }

    if (currentTab === 'weekOnWeek') {
      return <WeekOnWeekAnalysis analytics={allAnalytics} />;
    }

    if (currentTab === 'homeworkAnalysis') {
      return <HomeworkAnalysis analytics={allAnalytics} />;
    }

    if (currentTab === 'communicationAnalysis') {
      return <CommunicationAnalysis />;
    }

    if (currentTab === 'monthlyBreakdown') {
      if (isGettingAllAnalytics) {
        return (
          <>
            <Skeleton height={50} />
            <Skeleton count={15} className='mt-3' />{' '}
          </>
        );
      }

      console.log('monthlyBreakdownTotalValues: ', monthlyBreakdownTotalValues);

      if (!monthlyBreakdownTotalValues || !monthlyBreakdownTotalAverageValues || !monthlyBreakdownDailyValues) {
        return (
          <>
            <Select
              options={AnalysisTypeSelector}
              value={AnalysisTypeSelector.find((e) => currentMonthType === e.value)}
              onChange={(option) => {
                setCurrentMonthType(option?.value || 'SUBSCRIPTIONS');
              }}
              className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
            />

            <div className='flex'>
              <div className='flex flex-row space-2 p-6 mb-10 flex-wrap gap-y-7 gap-x-10 ml-auto mr-auto'>
                {monthlyBreakdownNavigation.map((item) => {
                  return (
                    <div key={item.name} className='flex justify-center'>
                      <button
                        onClick={() => {
                          setCurrentMonthToMonthTab(item.page);
                          console.log('item.date: ', item.date);
                        }}
                        className={classNames(
                          item.page === currentMonttoMonthTab
                            ? 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900  bg-gray-900 text-white'
                            : 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900',
                          isFetchingData || item.disabled
                            ? 'opacity-50'
                            : 'opacity-100 hover:bg-gray-500 hover:text-white',
                        )}
                      >
                        {item.name}
                      </button>
                    </div>
                  );
                })}
                Something Went Wrong!
              </div>
            </div>
          </>
        );
      }

      const totalAnalytics = {
        ...monthlyBreakdownTotalValues,
        ...monthlyBreakdownTotalAverageValues,
        ...monthlyBreakdownDailyValues,
      };

      const keys = totalAnalytics && Object.keys(totalAnalytics)?.length ? Object.keys(totalAnalytics).sort() : [];
      console.log('keys : ', keys);
      const charts: any = getChartData(keys, totalAnalytics);
      const stats: any = getStatsData(keys, totalAnalytics);
      const chartTitle = getChartTitle();
      const chartSubtitle = getChartSubtitle();
      console.log('charts : ', charts);
      console.log('stats : ', stats);
      console.log('chartTitle : ', chartTitle);
      console.log('chartSubtitle : ', chartSubtitle);

      return (
        <>
          <Select
            options={AnalysisTypeSelector}
            value={AnalysisTypeSelector.find((e) => currentMonthType === e.value)}
            onChange={(option) => {
              setCurrentMonthType(option?.value || 'SUBSCRIPTIONS');
            }}
            className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
          />

          <div className='flex'>
            <div className='flex flex-row space-2 p-6 mb-10 flex-wrap gap-y-7 gap-x-10 ml-auto mr-auto'>
              {monthlyBreakdownNavigation.map((item) => {
                return (
                  <div key={item.name} className='flex justify-center'>
                    <button
                      onClick={() => {
                        setCurrentMonthToMonthTab(item.page);
                        console.log('item.date: ', item.date);
                      }}
                      className={classNames(
                        item.page === currentMonttoMonthTab
                          ? 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900  bg-gray-900 text-white'
                          : 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900',
                        isFetchingData || item.disabled
                          ? 'opacity-50'
                          : 'opacity-100 hover:bg-gray-500 hover:text-white',
                      )}
                    >
                      {item.name}
                    </button>
                  </div>
                );
              })}

              <div className='flex flex-row flex-wrap'>
                <div className='flex-wrap gap-y-7 gap-x-2'>
                  {_renderStats({ title: chartTitle, subtitle: chartSubtitle, data: stats })}
                  {_renderCharts(charts)}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    if (currentTab === 'anyDates') {
      return (
        <div>
          <div>Date Analysis</div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
            <label htmlFor='expire-date' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
              Dates that Code is Valid (inclusive)
            </label>
            <div className='mt-1 sm:mt-0 '>
              <ReactDatePicker
                selected={monthStartDate}
                onChange={(dates: any) => {
                  const [start, end] = dates;

                  setMonthStartDate(start);
                  setMonthEndDate(end || null);
                }}
                startDate={monthStartDate}
                endDate={monthEndDate}
                selectsRange
              />

              <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
                <label htmlFor='cover-photo' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                  Type of Analysis
                </label>
                <Select
                  options={AnalysisTypeSelector}
                  value={AnalysisTypeSelector.find((e) => currentMonthType === e.value)}
                  onChange={(option) => {
                    setCurrentMonthType(option?.value || 'SUBSCRIPTIONS');
                  }}
                  className={`max-w-lg shadow-sm w-[200px] block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                />
              </div>
              <button
                disabled={isCalculating}
                onClick={() => {
                  if (monthStartDate && monthEndDate && currentMonthType) {
                    calculateDifference({
                      endDate: monthEndDate.getTime(),
                      startDate: monthStartDate.getTime(),
                      type: currentMonthType,
                    });
                  }
                }}
              >
                Run
              </button>
            </div>
          </div>
          <div className='flex flex-col gap-y-[10px]'>
            {difference && !difference.error ? (
              <>
                <div>
                  <p>Active Users: {difference.activeUsers}</p>
                </div>
                <div>
                  <p>Not finished onboarding: {difference.usersWhoDidntFinishOnboarding}</p>
                </div>
                <div>
                  <p>Lessons Completed: {difference.lessonsCompleted}</p>
                </div>
                <div>
                  <p>Sessions: {difference.sessions}</p>
                </div>
                <div>
                  <p>Subscriptions: {difference.subscriptionAccounts}</p>
                </div>
                <div>
                  <p>Wants:</p>
                  <div className={'pl-5'}>
                    {difference.wants.map((wantItem) => (
                      <div key={wantItem.label}>
                        <p>
                          {wantItem.label}: {wantItem.userCount}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <p>Goals:</p>
                  <div className={'pl-5'}>
                    {difference.goals.map((goalItem) => (
                      <div key={goalItem.label}>
                        <p>
                          {goalItem.label}: {goalItem.userCount}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <p>Age:</p>
                  <div className={'pl-5'}>
                    {difference.age.map((ageItem) => (
                      <div key={ageItem.label}>
                        <p>
                          {ageItem.label}: {ageItem.userCount}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <p>Subscriptions:</p>
                  <div className={'pl-5'}>
                    {difference.subscriptions.map((subItem) => (
                      <div key={subItem.label}>
                        <p>
                          {subItem.label}: {subItem.userCount}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <p>Wearables:</p>
                  <div className={'pl-5'}>
                    {difference.wearables.map((wItem) => (
                      <div key={wItem.label}>
                        <p>
                          {wItem.label}: {wItem.userCount}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <p>{difference?.error || null}</p>
            )}
          </div>
        </div>
      );
    }

    // const thisKey: string = getAnalyticsKey();
    const analyticsKey = currentTab as keyof AnalyticsType;

    const data = currentAnalytics?.[analyticsKey] || null;

    const keys = data && Object.keys(data).length ? Object.keys(data).sort() : [];

    const charts: any = getChartData(keys, data);
    const stats: any = getStatsData(keys, data);
    const chartTitle = getChartTitle();
    const chartSubtitle = getChartSubtitle();
    return (
      <div className='flex flex-row flex-wrap'>
        <div className='flex-wrap gap-y-7 gap-x-2'>
          {_renderStats({ title: chartTitle, subtitle: chartSubtitle, data: stats })}
          {_renderCharts(charts)}
        </div>
      </div>
    );
  };

  return (
    <div className='flex flex-col'>
      <div className='-my-6 divide-y  divide-gray-500/10'>
        <div className='flex'>
          <div className='flex flex-row space-2 p-6 mb-10 flex-wrap gap-y-7 gap-x-10 ml-auto mr-auto'>
            {navigation.map((item) => {
              return (
                <div key={item.name} className='flex justify-center'>
                  <button
                    disabled={isFetchingData || item.disabled}
                    onClick={() => setCurrentTab(item.page)}
                    className={classNames(
                      item.page === currentTab
                        ? 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900  bg-gray-900 text-white'
                        : 'border border-blue rounded-20 block rounded-lg px-5 py-2 text-base font-semibold leading-7 text-gray-900',
                      isFetchingData || item.disabled ? 'opacity-50' : 'opacity-100 hover:bg-gray-500 hover:text-white',
                    )}
                  >
                    {item.name}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={'flex gap-x-3 flex-row ml-auto mr-[10rem] mb-10'}>
        <p>
          Last synchronisation:{' '}
          <span className='font-bold'>
            {currentAnalytics?.updatedAt ? format(currentAnalytics.updatedAt?.toDate(), 'dd.MM.yyyy HH:mm') : '-'}{' '}
          </span>
        </p>
        <button className={'font-bold'} disabled={isRecalculating} onClick={() => runCalculations()}>
          {isRecalculating ? <Spinner className='h-[20px] w-[20px]' /> : 'Sync'}{' '}
        </button>
      </div>
      {!totalAnalytics || !todaysAnalytics ? null : _renderContent()}
    </div>
  );
};

export default HomePage;
