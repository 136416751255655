import { AfterSessionType } from '../collections/afterSessionSurvey';
import { SessionType } from '../modeltypes/session';
import { AccountType } from '../modeltypes/account';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import { UserType } from 'src/modeltypes/user';

export type ResearchCSVType = {
  sessionId: string;
  email?: string;
  userId?: string;
  platform: string;
  sessionName: string;
  lessonFinished: boolean;
  numberOfPauses?: number;
  pauseTime: number;
  uid?: string;
  sessionStart: string;
  sessionEnd: string;
  timeLeft?: number;
  preSurveyStartTime: string;
  preSurveyEndTime: string;
  preConcentrationAnswer: number | string;
  preSensorClarityAnswer: number | string;
  preEquanimityAnswer: number | string;
  postSurveyStartTIme: string;
  postSurveyEndTime: string;
  postConcentrationAnswer: number | string;
  postSensorClarityAnswer: number | string;
  postEquanimityAnswer: number | string;
  sessionTimeInSeconds: number | string;
};

export const getFullDate = (date?: Timestamp) => {
  if (!date) {
    return 'not set';
  }
  return format(date.toDate(), 'dd-MM-yyyy HH:mm:ss');
};
export const getFullDateWithTimezone = (date?: Timestamp, tzString?: string) => {
  if (!date) {
    return 'not set';
  }
  return format(
    tzString ? new Date(date.toDate().toLocaleString('en-US', { timeZone: tzString })) : date.toDate(),
    'dd-MM-yyyy HH:mm:ss',
  );
};
export const prepareCSV = ({
  sessions,
  afterSessions,
  beforeCCE,
  users,
  timezone,
  isObfuscated = false,
}: {
  isObfuscated?: boolean;
  afterSessions: AfterSessionType[] | null;
  beforeCCE: AfterSessionType[] | null;
  sessions: SessionType[] | null;
  users: AccountType[] | null;
  timezone?: string;
}): ResearchCSVType[] | null => {
  if (!sessions || sessions.length === 0) {
    return null;
  }

  return sessions.map((session): ResearchCSVType => {
    const user = users?.find((el) => el?.uid === session?.uid);
    const beforeSurvey = beforeCCE?.find((el) => el.id === session?.beforeCCE?.id);
    const afterSurvey = afterSessions?.find((el) => el.id === session?.afterSessionSurvey?.id);

    const email = isObfuscated ? {} : { email: user?.email || '' };
    return {
      sessionId: session.id,
      // uid: session.uid, // this is the userID
      ...email,
      platform: session.platform,
      sessionName: session.nameOfSession,
      lessonFinished: session.lessonFinished,
      pauseTime: session.pauseTime,
      sessionStart: getFullDateWithTimezone(session.sessionStart, timezone),
      sessionEnd: getFullDateWithTimezone(session.sessionEnd, timezone),
      sessionTimeInSeconds:
        session.sessionStart && session.sessionEnd
          ? Math.round((session.sessionEnd.toDate().getTime() - session.sessionStart.toDate().getTime()) / 1000)
          : '-',
      preSurveyStartTime: getFullDateWithTimezone(beforeSurvey?.showTime, timezone),
      preSurveyEndTime: getFullDateWithTimezone(beforeSurvey?.endTime, timezone),
      preConcentrationAnswer: beforeSurvey?.answers?.concentration || '-',
      preEquanimityAnswer: beforeSurvey?.answers?.equanimity || '-',
      preSensorClarityAnswer: beforeSurvey?.answers?.sensory_clarity || '-',
      postSurveyStartTIme: getFullDateWithTimezone(afterSurvey?.showTime, timezone),
      postSurveyEndTime: getFullDateWithTimezone(afterSurvey?.endTime, timezone),
      postConcentrationAnswer: afterSurvey?.answers?.concentration || '-',
      postEquanimityAnswer: afterSurvey?.answers?.equanimity || '-',
      postSensorClarityAnswer: afterSurvey?.answers?.sensory_clarity || '-',
    };
  });
};

export const prepareCSVforUser = ({
  sessions,
  afterSessions,
  beforeCCE,
  user,
  timezone,
}: {
  afterSessions: AfterSessionType[] | null;
  beforeCCE: AfterSessionType[] | null;
  sessions: SessionType[] | null;
  user: UserType;
  timezone?: string;
}): ResearchCSVType[] | null => {
  console.log('prepareCSVforUser: sessions: ', sessions);
  console.log('prepareCSVforUser: afterSessions: ', afterSessions);
  console.log('prepareCSVforUser: beforeCCE: ', beforeCCE);
  console.log('prepareCSVforUser: beforeCCE: ', beforeCCE);

  if (!sessions || sessions.length === 0) {
    return null;
  }

  return sessions.map((session): ResearchCSVType => {
    const beforeSurvey = beforeCCE?.find((el) => el.id === session?.beforeCCE?.id);
    const afterSurvey = afterSessions?.find((el) => el.id === session?.afterSessionSurvey?.id);

    return {
      sessionId: session.id,
      uid: session.uid,
      // email: user?.email || '',
      userId: user?.id || '',
      platform: session.platform,
      sessionName: session.nameOfSession,
      lessonFinished: session.lessonFinished,
      pauseTime: session.pauseTime,
      sessionStart: getFullDateWithTimezone(session.sessionStart, timezone),
      sessionEnd: getFullDateWithTimezone(session.sessionEnd, timezone),
      sessionTimeInSeconds:
        session.sessionStart && session.sessionEnd
          ? Math.round((session.sessionEnd.toDate().getTime() - session.sessionStart.toDate().getTime()) / 1000)
          : '-',
      preSurveyStartTime: getFullDateWithTimezone(beforeSurvey?.showTime, timezone),
      preSurveyEndTime: getFullDateWithTimezone(beforeSurvey?.endTime, timezone),
      preConcentrationAnswer: beforeSurvey?.answers?.concentration || '-',
      preEquanimityAnswer: beforeSurvey?.answers?.equanimity || '-',
      preSensorClarityAnswer: beforeSurvey?.answers?.sensory_clarity || '-',
      postSurveyStartTIme: getFullDateWithTimezone(afterSurvey?.showTime, timezone),
      postSurveyEndTime: getFullDateWithTimezone(afterSurvey?.endTime, timezone),
      postConcentrationAnswer: afterSurvey?.answers?.concentration || '-',
      postEquanimityAnswer: afterSurvey?.answers?.equanimity || '-',
      postSensorClarityAnswer: afterSurvey?.answers?.sensory_clarity || '-',
    };
  });
};
