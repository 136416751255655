// *******************************************************
// Branches List
// -------------------------------------------------------
// This is a list of science data
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';

// *******************************************
// Component Imports
// -------------------------------------------
import { ChevronDownIcon } from '@heroicons/react/solid';

// *******************************************
// Hooks Import
// -------------------------------------------
import { useAppContext } from '../contexts/appContext';

// *******************************************
// Action Imports
// -------------------------------------------
import { getBlob, getMetadata, StorageReference } from 'firebase/storage';
import { getUserById } from '../collections/user';
import { getAccountById } from '../collections/account';
import { getStorageRef } from '../firebase/utils';
import { getAllScienceData } from '../collections/scienceData';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { dieIfNullOrUndef } from '../utility/GeneralUtilities';
import SearchBar from './SearchBar';
import { toast } from 'react-toastify';

// *******************************************
// Styles Imports
// -------------------------------------------

// *******************************************
// Constants
// -------------------------------------------

// *******************************************
// Types
// -------------------------------------------
type ScienceListType = {
  id: string;
  createdAt: Date;
  userEmail: string;
  userName: string;
  lesson: string;
  audioLength?: string;
  audioUrl: StorageReference | null;
  textUrl: StorageReference | null;
  hexoDataUrl: StorageReference | null;
};

type ScienceListTypeWithoutNullFields = Omit<ScienceListType, 'audioUrl' | 'textUrl' | 'hexoDataUrl'>;

const BranchesList = () => {
  const { searchTerm } = useAppContext();
  const [scienceData, setScienceData] = useState<ScienceListType[]>();
  const [sortFilter, setSortFilter] = useState<{
    key: keyof ScienceListTypeWithoutNullFields;
    order: 'asc' | 'desc';
  }>();
  const [isLoading, setIsLoading] = useState(true);

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!scienceData?.length) return [];
    return scienceData
      .filter((item) => {
        const matchEmail = item.userEmail && item.userEmail.match(regex);
        const matchName = item.userName && item.userName.match(regex);
        const matchLesson = item.lesson && item.lesson.match(regex);
        return matchName || matchEmail || matchLesson;
      })
      .sort((a, b) => {
        if (!sortFilter || sortFilter.key === 'audioLength') {
          return 0;
        }
        if (sortFilter.key === 'createdAt') {
          if (sortFilter.order === 'desc') {
            return a.createdAt.getTime() - b.createdAt.getTime();
          }
          if (sortFilter.order === 'asc') {
            return b.createdAt.getTime() - a.createdAt.getTime();
          }
        } else if (sortFilter.key) {
          if (sortFilter.order === 'desc') {
            return a[sortFilter.key].toString().localeCompare(b[sortFilter.key].toString());
          }
          if (sortFilter.order === 'asc') {
            return b[sortFilter.key].toString().localeCompare(a[sortFilter.key].toString());
          }
        }
        return 0;
      });
  }, [searchTerm, scienceData, sortFilter]);

  useEffect(() => {
    const prepareScienceData = async () => {
      const scienceDataArray = await getAllScienceData();
      const tempArray = scienceDataArray.map(async (scienceObject) => {
        const audioRef = scienceObject.audioUrl ? getStorageRef(scienceObject.audioUrl) : null;
        const textRef = scienceObject.dataUrl ? getStorageRef(scienceObject.dataUrl) : null;
        const hexoRef = scienceObject.hexoDataUrl ? getStorageRef(scienceObject.hexoDataUrl) : null;

        const audioMeta = audioRef
          ? await getMetadata(audioRef).catch(() => console.error('its not in this storage'))
          : null;

        const user = await getUserById(scienceObject.user);
        const account = await getAccountById(scienceObject.user);

        return Promise.resolve<ScienceListType>({
          id: scienceObject.id,
          audioLength: audioMeta?.customMetadata?.audioLength
            ? `${(Number.parseInt(audioMeta.customMetadata.audioLength, 10) / 60000).toFixed(0)} min ${(
                (Number.parseInt(audioMeta.customMetadata.audioLength, 10) / 1000) %
                60
              ).toFixed(0)} secs`
            : 'No audio',
          audioUrl: audioRef,
          lesson: `${scienceObject.session} ${scienceObject.lesson}`,
          textUrl: textRef,
          createdAt: dieIfNullOrUndef(scienceObject.createdAt?.toDate()),
          userEmail: account?.email || '',
          userName: `${user?.firstName} ${user?.lastName}`,
          hexoDataUrl: hexoRef,
        });
      });

      return Promise.all(tempArray);
    };

    prepareScienceData()
      .then((array) => {
        setScienceData(array);
        setIsLoading(false);
      })
      .catch((e) => console.error(e));
  }, []);

  const downloadFile = async (path: StorageReference) => {
    if (path) {
      const blob = await getBlob(path);
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = path.parent?.name || path.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const sortRequest = (fieldName: keyof ScienceListTypeWithoutNullFields) => {
    let order: 'asc' | 'desc' = 'asc';
    if (sortFilter && sortFilter.key === fieldName && sortFilter.order === 'asc') {
      order = 'desc';
    }
    setSortFilter({
      key: fieldName,
      order: order,
    });
  };

  const toastWrap = (downloadFunction: Promise<void>) => {
    toast.promise(downloadFunction, {
      pending: `Preparing file...`,
      error: "Can't do it now, try again.",
      success: `File is being downloaded!`,
    });
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='mt-8 flex flex-col'>
        <SearchBar />
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                        onClick={() => sortRequest('createdAt')}
                      >
                        <p className='group inline-flex'>
                          Created At
                          <span
                            className={`${sortFilter?.key !== 'createdAt' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer'
                        onClick={() => sortRequest('userEmail')}
                      >
                        <p className='group inline-flex'>
                          User Email
                          <span
                            className={`${sortFilter?.key !== 'userEmail' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                        onClick={() => sortRequest('userName')}
                      >
                        <p className='group inline-flex'>
                          User Name
                          <span
                            className={`${sortFilter?.key !== 'userName' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hover:cursor-pointer'
                        onClick={() => sortRequest('lesson')}
                      >
                        <p className='group inline-flex'>
                          Lesson
                          <span
                            className={`${sortFilter?.key !== 'lesson' && 'invisible'} ${
                              sortFilter?.order === 'asc' && 'rotate-180'
                            } ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible`}
                          >
                            <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        </p>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Audio Length
                      </th>
                      <th scope='col' colSpan={3} className='text-center'>
                        <p>Download files</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems?.map((science) => (
                      <tr key={science.id} className='hover:bg-stone-200'>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className='ml-4'>
                              <div className='text-gray-500'>{format(science.createdAt, 'dd.MM.yyyy, HH:mm')}</div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='font-medium text-gray-900'>{science.userEmail}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>{science.userName}</div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{science.lesson}</td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{science?.audioLength}</td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              science.audioUrl ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => (science.audioUrl ? toastWrap(downloadFile(science.audioUrl)) : null)}
                          >
                            {science.audioUrl ? 'Audio' : '-'}
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              science.textUrl ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => (science.textUrl ? toastWrap(downloadFile(science.textUrl)) : null)}
                          >
                            {science.textUrl ? 'Data' : '-'}
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'>
                          <div
                            className={`text-indigo-600 ${
                              science.hexoDataUrl ? 'hover:text-indigo-900 cursor-pointer' : ''
                            }`}
                            onClick={() => (science.hexoDataUrl ? toastWrap(downloadFile(science.hexoDataUrl)) : null)}
                          >
                            {science.hexoDataUrl ? 'Hexo Data' : '-'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(BranchesList);
