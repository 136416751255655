/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod';
import { coerceInteger, timestampType } from './shared';
import { userIdSchema } from './id';
import { IdFactory } from '../models/dalaccess';
import { UserType, UserTypeBase, UserTypeBuilder, UserTypeNew } from '../modeltypes/user';

const wearablesTypeSchema = z.object({ isUsingIt: z.boolean(), types: z.array(z.string()).optional() });
export const userTypeSchema = z.object({
  id: userIdSchema,
  firstName: z.string().default(''),
  lastName: z.string().default(''),
  currentLesson: z
    .object({
      nodeId: z.string(),
      trackId: z.string(),
      type: z.string(),
      unitId: z.string(),
    })
    .optional(),
  createdAt: timestampType().optional(),
  updatedAt: timestampType().optional(),
  program: z.string().optional(),
  consecutiveDays: z.number().optional(),
  options: z.record(z.unknown()).optional(),
  birthdate: z.string().optional(),
  latestDailyLife: z.record(z.unknown()).optional(),
  latestPostMed: z.record(z.unknown()).optional(),
  furthestHitsCount: z.record(z.unknown()).optional(),
  preferredDayTime: z.string().optional(),
  lessonStreak: z.record(z.unknown()).optional(),
  // This is a hack to tolerate the fact that some code keeps writing strings into `age`.
  age: z.union([z.number(), z.string()]).optional().transform(coerceInteger),
  latestHomeworkAssignment: z.record(z.unknown()).optional(),
  timezone: z.string().optional(),
  legacyUser: z.boolean().optional(),
  latestDailyDiary: z.record(z.unknown()).optional(),
  goals: z.array(z.string()).optional(),
  lastLesson: z.record(z.unknown()).optional(),
  latestDay: z.number().optional(),
  sessionStatus: z.record(z.unknown()).optional(),
  furthestDay: z.number().optional(),
  isGuided: z.boolean().optional(),
  deleteAccount: z.boolean().default(false),
  experience: z.string().optional(),
  goal: z.string().optional(),
  wants: z.array(z.string()).optional(),
  onboardFinished: z.boolean().optional(),
  email: z.string().optional(),
  wearables: wearablesTypeSchema.optional(),
});

export const userTypeBaseSchema = userTypeSchema.partial();

export const userTypeBuilderSchema = userTypeBaseSchema
  .omit({ id: true })
  .merge(z.object({ id: userIdSchema.default(() => IdFactory.User()) }));

export const userTypeNewSchema = userTypeSchema.omit({ id: true }).merge(z.object({ id: userIdSchema.optional() }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSUnusedLocalSymbols
function _showErrorIfSchemaOutOfSyncWithTypes_doNotCall() {
  // This should NEVER be called. It's just here so that we get an error if the inferred
  // types of the schemas parse methods get out of sync with the types they're parsing to.
  const w: UserType = userTypeSchema.parse(undefined);
  const x: UserTypeBase = userTypeBaseSchema.parse(undefined);
  const y: UserTypeBuilder = userTypeBuilderSchema.parse(undefined);
  const z: UserTypeNew = userTypeNewSchema.parse(undefined);
  return !!w && !!x && !!y && !!z;
}
