/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { BellIcon, HomeIcon, MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import { useAppContext } from '../contexts/appContext';
import { Pages } from '../enums/pages';
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import CohortsPage from '../pages/cohorts/CohortsPage';
import CohortForm from '../pages/cohorts/CohortForm';
import CoachesPage from '../pages/coaches/CoachesPage';
import CoachForm from '../pages/coaches/CoachForm';
import IncentivesPage from '../pages/incentives/IncentivesPage';
import IncentivesForm from '../pages/incentives/IncentivesForm';
import HomePage from '../pages/homePage/Home';
import CompanyPage from '../pages/companies/CompanyPage';
import CompanyForm from '../pages/companies/CompanyForm';
import CohortDashboardPage from '../pages/cohorts/CohortDashboardPage';
import CohortSciencePage from '../pages/cohorts/CohortSciencePage';
import CohortMeetingsPage from '../pages/cohortMeetings/CohortMeetingsPage';
import CohortMeetingForm from '../pages/cohortMeetings/CohortMeetingForm';
import UsersPage from '../pages/users/UsersPage';
import UserForm from '../pages/users/UserForm';

import AccessCodesPage from '../pages/accessCodes/AccessCodesPage';
import AccessCodeForm from '../pages/accessCodes/AccessCodeForm';

import { auth } from '../firebase/firebaseConfig';
import RawData from '../pages/RawData';
import CohortReportPage from '../pages/cohorts/CohortReportPage';

import LessonsPage from '../pages/lessons/LessonsPage';
import LessonImportPage from '../pages/lessons/LessonImport';
import * as TabIcons from '../assets/tabIcons/tabIcons';
import ScienceLoversPage from '../pages/scienceLovers/ScienceLoversPage';

import ChapterForm from '../pages/chapter/ChapterForm';
import { InsightsContextProvider } from '../contexts/insightsContext';
import PathTypeForm from 'src/pages/paths/PathTypeForm';
import PathTypePage from 'src/pages/paths/QuizPage';
import TagForm from 'src/pages/tags/TagForm';
import TagsPage from 'src/pages/tags/TagsPage';
import QuizPage from 'src/pages/paths/QuizPage';
import QuizForm from 'src/pages/paths/QuizForm';
import TaskForm from 'src/pages/paths/TaskForm';
import TaskPage from 'src/pages/paths/TaskPage';
import LearningGamePage from 'src/pages/paths/LearningGamePage';
import LearningGameForm from 'src/pages/paths/LearningGameForm';

import AppUpdateInfoForm from 'src/pages/appUpdateInfo/AppUpdateInfoForm';
import AppUpdateInfoPage from 'src/pages/appUpdateInfo/AppUpdateInfoPage';

import ChallengeForm from 'src/pages/paths/ChallengeForm';
import ChallengePage from 'src/pages/paths/ChallengePage';
import NodeFormPage from '../pages/nodes/NodeFormPage';
import UnitPage from '../pages/units/UnitPage';
import UnitForm from '../pages/units/UnitForm';
import LessonForm from '../pages/lessons/LessonForm';
import AudioNodesFormPage from '../pages/audioNodes/AudioNodesFormPage';
import TrackForm from 'src/pages/track/TrackForm';
import TrackPage from 'src/pages/track/TrackPage';
import RewardPage from 'src/pages/rewards/RewardPage';
import RewardForm from 'src/pages/rewards/RewardForm';
import ChallengeSwitchPage from 'src/pages/paths/ChallengeSwitchPage';
import ChallengeSwitchForm from 'src/pages/paths/ChallengeSwitchForm';
import GoalPage from 'src/pages/goals/GoalPage';
import GoalForm from 'src/pages/goals/GoalForm';
import NotificationsPage from '../pages/notifications/NotificationsPage';
import NotificationsForm from '../pages/notifications/NotificationsForm';
import ValidateElementModal from './modals/ValidateElementModal';
import UserJourney from '../pages/users/UserJourney';
import TaskSwitchForm from 'src/pages/paths/TaskSwitchForm';
import TaskSwitchPage from 'src/pages/paths/TaskSwitchPage';
import FunctionRunsPage from '../pages/functionRuns/FunctionRunsPage';
import FunctionRunDetails from '../pages/functionRuns/FunctionRunDetails';
import LessonAudioChunksPage from '../pages/lessonAudioChunks/LessonAudioChunksPage';
import LessonAudioChunksForm from '../pages/lessonAudioChunks/LessonAudioChunksForm';
// import LessonAudioPracticePage from 'src/pages/lessonAudioPractice/LessonAudioPracticePage';
// import LessonAudioPracticeForm from 'src/pages/lessonAudioPractice/LessonAudioPracticeForm';
import HomeNonAuth from 'src/pages/HomeNonAuth';
import DataSciencePage from 'src/pages/DataSciencePage';
import EmailPage from 'src/pages/emails/EmailPage';
import EmailForm from 'src/pages/emails/EmailForm';
import CompanyDashboard from '../pages/companies/CompanyDashboard';
import CompanyStatisticsPage from '../pages/companies/CompanyStatisticsPage';
import AllCohortReportPage from 'src/pages/cohorts/AllCohortReportPage';
import UserScience from 'src/pages/users/UserScience';
import UserRewards from 'src/pages/users/UserRewards';

interface NavTs {
  name?: string;
  page: Pages;
  href?: string;
  Icon?: any;
  access?: string[] | undefined;
}

const navigation: NavTs[] = [
  {
    name: Pages.AccessCode,
    page: Pages.AccessCode,
    href: '#',
    Icon: TabIcons.AccessCode,
    access: ['admin'],
  },
  {
    name: Pages.Users,
    page: Pages.Users,
    href: '#',
    Icon: TabIcons.UserIcon,
    access: ['admin'],
  },
  {
    name: Pages.Coaches,
    page: Pages.Coaches,
    href: '#',
    Icon: TabIcons.CoachIcon,
    access: ['admin'],
  },
  {
    name: Pages.Companies,
    page: Pages.Companies,
    href: '#',
    Icon: TabIcons.CompanyIcon,
    access: ['admin'],
  },
  {
    name: Pages.Cohorts,
    page: Pages.Cohorts,
    href: '#',
    Icon: TabIcons.CohortIcon,
    access: ['admin', 'coach', 'science'],
  },

  // {
  //   name: Pages.Incentives,
  //   page: Pages.Incentives,
  //   href: '#',
  //   Icon: TabIcons.IncentivesIcon,
  //   access: ['admin'],
  // },
  {
    name: 'Cohort Sessions',
    page: Pages.CohortSessions,
    Icon: TabIcons.SessionsIcon,
    access: ['admin'],
  },
  {
    name: 'Rewards',
    page: Pages.Rewards,
    Icon: TabIcons.RewardsIcon,
    access: ['admin'],
  },
  {
    name: Pages.Goals,
    Icon: TabIcons.TaskIcon,
    page: Pages.Goals,
    access: ['admin', 'audio'],
  },

  {
    name: Pages.Unit,
    page: Pages.Unit,
    href: '#',
    Icon: TabIcons.ProgramsIcon,
    access: ['admin', 'audio'],
  },
  {
    name: Pages.Tracks,
    page: Pages.Tracks,
    href: '#',
    Icon: TabIcons.TrackIcon,
    access: ['admin', 'audio'],
  },

  {
    name: 'Path Types',
    page: Pages.PathTypes,
    Icon: TabIcons.PathTypesIcon,
    access: ['admin'],
  },
  {
    name: 'Tags',
    page: Pages.Tags,
    Icon: TabIcons.TagsIcon,
    access: ['admin'],
  },
  {
    name: Pages.QuizItems,
    page: Pages.QuizItems,
    Icon: TabIcons.QuizIcon,
    access: ['admin', 'audio'],
  },

  {
    name: 'Notifications',
    page: Pages.Notifications,
    Icon: TabIcons.PaperClip,
    access: ['admin'],
  },
  {
    name: Pages.AppUpdateInfo,
    Icon: TabIcons.AppUpdateInfoIcon,
    page: Pages.AppUpdateInfo,
    access: ['admin'],
  },
  {
    name: Pages.Email,
    Icon: TabIcons.EmailIcon,
    page: Pages.Email,
    access: ['admin'],
  },

  {
    name: 'Function Runs',
    Icon: TabIcons.MoveIcon,
    page: Pages.FunctionRuns,
    access: ['admin'],
  },
  {
    name: 'Data Science',
    Icon: TabIcons.DataScienceIcon,
    page: Pages.DataScience,
    access: ['admin', 'science'],
  },
  {
    name: 'Science Lovers',
    page: Pages.ScienceLovers,
    Icon: TabIcons.ScienceLoversIcon,
    access: ['admin', 'science'],
  },
];

const paths: NavTs[] = [
  {
    name: Pages.Lessons,
    page: Pages.Lessons,
    Icon: TabIcons.LessonIcon,
    access: ['admin', 'audio'],
  },
  {
    name: 'Lesson Audio Chunks',
    page: Pages.LessonAudioChunks,
    Icon: TabIcons.LessonIcon,
    access: ['admin', 'audio'],
  },
  {
    name: Pages.Tasks,
    Icon: TabIcons.TaskIcon,
    page: Pages.Tasks,
    access: ['admin', 'audio'],
  },
  {
    name: Pages.Challenges,
    Icon: TabIcons.ChallengeIcon,
    page: Pages.Challenges,
    access: ['admin', 'audio'],
  },

  {
    name: 'Challenge Switch',
    Icon: TabIcons.ChallengeIcon,
    page: Pages.ChallengeSwitches,
    access: ['admin', 'audio'],
  },
  {
    name: 'Task Switch',
    Icon: TabIcons.TaskIcon,
    page: Pages.TaskSwitches,
    access: ['admin', 'audio'],
  },
  {
    name: Pages.LearningGames,
    page: Pages.LearningGames,
    Icon: TabIcons.LearningGames,
    access: ['admin', 'audio'],
  },

  {
    name: 'Data Science',
    Icon: TabIcons.TaskIcon,
    page: Pages.DataScience,
    access: ['admin', 'audio'],
  },
];

const userNavigation = [
  {
    name: 'Your Profile',
  },
  { name: 'Settings' },
  {
    name: 'Sign out',
    onClick: () => auth.signOut(),
  },
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, breadcrumbPaths, setSearchTerm, role, validateElement, setValidateElement } = useAppContext();
  const [currentPage, setCurrentPage] = useState<Pages>();

  const [showPathTypes, setShowPathTypes] = useState(false);

  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const [currentUrl, setCurrentUrl] = React.useState(pathname);

  useEffect(() => {
    setCurrentUrl(pathname);
  }, [pathname]);

  useEffect(() => {
    setSearchTerm('');
    setSidebarOpen(false);
    setValidateElement(null);
  }, [currentUrl, setSearchTerm]);

  // const [pathTypes, setPathTypes] = useState([]);
  // useEffect(() => {

  // },[]);

  const renderBreadcrumbPath = () => {
    return (
      <nav className='flex mb-10' aria-label='Breadcrumb'>
        <ol role='list' className='bg-white rounded-md shadow px-6 flex space-x-4'>
          <li className='flex'>
            <div className='flex items-center'>
              <div
                onClick={() => navigate('/')}
                className='text-gray-400 hover:text-gray-500 hover:cursor-pointer py-3'
              >
                <HomeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
                <span className='sr-only'>Home</span>
              </div>
            </div>
          </li>
          {breadcrumbPaths?.map(({ label, path }) => (
            <li key={label + path} className='flex'>
              <div className='flex items-center'>
                <svg
                  className='flex-shrink-0 w-6 h-full text-gray-200'
                  viewBox='0 0 24 44'
                  preserveAspectRatio='none'
                  fill='currentColor'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                >
                  <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                </svg>
                <p
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 hover:cursor-pointer'
                  onClick={() => navigate(path)}
                >
                  {label}
                </p>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    );
  };

  const renderPathTypesHiddenNavigation = () => (
    <Fragment key={'hidden'}>
      <div
        className={classNames(
          Pages.PathTypes === currentPage
            ? 'bg-gray-900 text-white'
            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
          'group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:cursor-pointer',
        )}
        onClick={() => setShowPathTypes(!showPathTypes)}
      >
        <TabIcons.PathTypesIcon
          fill={'#FFF'}
          className={classNames(
            Pages.PathTypes === currentPage ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
            'mr-4 flex-shrink-0 h-6 w-6',
          )}
        />
        Path Types
      </div>
      <div
        className={`flex-1 flex flex-col ${
          showPathTypes ? 'h-[300px]' : 'h-0'
        } overflow-hidden transition-all ease-in-out duration-300`}
      >
        <nav className='flex-1 px-2 py-4 space-y-1'>
          {paths
            .filter(({ access }) => role && access?.includes(role))
            .map(({ name, Icon, page }, index) => (
              <Link
                to={page || name}
                key={`${name}-${index}`}
                onClick={() => {
                  setCurrentPage(page);
                }}
              >
                <div
                  key={name}
                  className={classNames(
                    name === currentPage
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  )}
                >
                  <Icon
                    fill={'#FFF'}
                    className={classNames(
                      name === currentPage ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                      'mr-4 flex-shrink-0 h-6 w-6',
                    )}
                  />
                  {name?.length && name.charAt(0).toUpperCase() + name.slice(1)}
                </div>
              </Link>
            ))}
        </nav>
      </div>
    </Fragment>
  );

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-40 md:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 flex z-40'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex-shrink-0 flex items-center px-4'>
                    <img className='h-10 w-auto' src={require('../logo.png')} alt='Workflow' />
                  </div>
                  <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                    <nav className='px-2 space-y-1'>
                      {navigation
                        .filter(({ access }) => role && access?.includes(role))
                        .map(({ name, Icon, page }, index) => {
                          return (
                            <div key={`${page}-${index}`}>
                              <Link to={page} key={`${page}-${index}`}>
                                <div
                                  className={classNames(
                                    name === currentPage
                                      ? `bg-gray-900 text-white`
                                      : `text-gray-300 hover:bg-gray-700 hover:text-white`,
                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                                  )}
                                >
                                  <Icon
                                    className={classNames(
                                      name === currentPage
                                        ? 'text-gray-300'
                                        : 'text-gray-400 group-hover:text-gray-300',
                                      'mr-4 flex-shrink-0 h-6 w-6',
                                    )}
                                    fill={'#FFF'}
                                  />
                                  {name?.length && name?.charAt(0)?.toUpperCase() + name?.slice(1)}
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className='flex-shrink-0 w-14' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex-1 flex flex-col min-h-0 bg-gray-800'>
            <div
              className='flex items-center h-20 flex-shrink-0 px-4 bg-gray-900 hover:cursor-pointer'
              onClick={() => navigate('/')}
            >
              <img className='h-14 w-auto m-auto' src={require('../logo.png')} alt='Workflow' />
            </div>
            <div className='flex-1 flex flex-col overflow-y-auto'>
              <nav className='flex-1 px-2 py-4 space-y-1'>
                {navigation
                  .filter(({ access }) => role && access?.includes(role))
                  .map(({ name, Icon, page }, index) =>
                    page === Pages.PathTypes ? (
                      renderPathTypesHiddenNavigation()
                    ) : (
                      <Link
                        to={page}
                        key={`${name}-${index}`}
                        onClick={() => {
                          setCurrentPage(page);
                          setShowPathTypes(false);
                        }}
                      >
                        <div
                          key={name}
                          className={classNames(
                            page === currentPage
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                          )}
                        >
                          <Icon
                            fill={'#FFF'}
                            className={classNames(
                              page === currentPage ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6',
                            )}
                          />
                          {name?.length && name.charAt(0).toUpperCase() + name.slice(1)}
                        </div>
                      </Link>
                    ),
                  )}
              </nav>
            </div>
          </div>
        </div>
        <div className='md:pl-64 flex flex-col'>
          <div className='sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow'>
            <button
              type='button'
              className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
            </button>
            <div className='flex-1 px-4 flex justify-end'>
              <div className='ml-4 flex items-center md:ml-6'>
                <button
                  type='button'
                  className='bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button>

                {/* Profile dropdown */}
                <Menu as='div' className='ml-3 relative'>
                  <div>
                    <Menu.Button className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                      <span className='sr-only'>Open user menu</span>
                      {user && user.photoURL ? (
                        <img src={user.photoURL} className='h-8 w-8 rounded-full overflow-clip' alt={'userPhoto'} />
                      ) : (
                        <TabIcons.UserIcon className='h-8 w-8 rounded-full overflow-clip' />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      {userNavigation.map((item, index) => (
                        <Menu.Item key={`${item.name}-${index}`}>
                          {({ active }) => (
                            <p
                              onClick={item.onClick ? item.onClick : () => null}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer',
                              )}
                            >
                              {item.name}
                            </p>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className='flex-1'>
            <div className='py-6'>
              <div className='max-w-[80vw] mx-auto px-2 sm:px-4 md:px-6'>
                {renderBreadcrumbPath()}
                <Routes>
                  <Route path={'/'} element={role && ['admin'].includes(role) ? <HomePage /> : <HomeNonAuth />} />
                  {role && ['admin', 'science'].includes(role) && (
                    <>
                      <Route path={'/'} element={<HomePage />} />

                      <Route path={`${Pages.ScienceLovers}`} element={<ScienceLoversPage />} />
                    </>
                  )}
                  {role && role === 'admin' && (
                    <>
                      <Route path={`${Pages.Companies}`} element={<CompanyPage />} />
                      <Route path={`${Pages.Companies}/:id`} element={<CompanyForm />} />
                      <Route path={`${Pages.Companies}/:id/dashboard`} element={<CompanyDashboard />} />
                      <Route
                        path={`${Pages.Companies}/:id/statistics`}
                        element={
                          <InsightsContextProvider>
                            <CompanyStatisticsPage />
                          </InsightsContextProvider>
                        }
                      />

                      <Route path={'' + Pages.Cohorts + '/:id'} element={<CohortForm />} />

                      <Route path={'' + Pages.Coaches + '/*'} element={<CoachesPage />} />
                      <Route path={'' + Pages.Coaches + '/:id'} element={<CoachForm />} />

                      <Route path={'' + Pages.Tags + '/*'} element={<TagsPage />} />
                      <Route path={'' + Pages.Tags + '/:id'} element={<TagForm />} />

                      <Route path={'' + Pages.Tracks + '/*'} element={<TrackPage />} />
                      <Route path={'' + Pages.Tracks + '/:id'} element={<TrackForm />} />

                      <Route path={'' + Pages.PathTypes + '/*'} element={<PathTypePage />} />
                      <Route path={'' + Pages.PathTypes + '/:id'} element={<PathTypeForm />} />

                      <Route path={'' + Pages.Goals + '/*'} element={<GoalPage />} />
                      <Route path={'' + Pages.Goals + '/:id'} element={<GoalForm />} />

                      <Route path={'' + Pages.Challenges + '/*'} element={<ChallengePage />} />
                      <Route path={'' + Pages.Challenges + '/:id'} element={<ChallengeForm />} />
                      <Route path={`${Pages.Challenges}/:id/${Pages.Node}/:nodeId`} element={<NodeFormPage />} />

                      <Route path={'' + Pages.Tasks + '/*'} element={<TaskPage />} />
                      <Route path={'' + Pages.Tasks + '/:id'} element={<TaskForm />} />

                      <Route path={'' + Pages.AppUpdateInfo + '/*'} element={<AppUpdateInfoPage />} />
                      <Route path={'' + Pages.AppUpdateInfo + '/:id'} element={<AppUpdateInfoForm />} />

                      <Route path={'' + Pages.Email + '/*'} element={<EmailPage />} />
                      <Route path={'' + Pages.Email + '/:id'} element={<EmailForm />} />

                      <Route path={'' + Pages.Rewards + '/*'} element={<RewardPage />} />
                      <Route path={'' + Pages.Rewards + '/:id'} element={<RewardForm />} />

                      <Route path={'' + Pages.QuizItems + '/*'} element={<QuizPage />} />
                      <Route path={'' + Pages.QuizItems + '/:id'} element={<QuizForm />} />

                      <Route path={'' + Pages.LearningGames + '/*'} element={<LearningGamePage />} />
                      <Route path={'' + Pages.LearningGames + '/:id'} element={<LearningGameForm />} />

                      <Route path={'' + Pages.ChallengeSwitches + '/*'} element={<ChallengeSwitchPage />} />
                      <Route path={'' + Pages.ChallengeSwitches + '/:id'} element={<ChallengeSwitchForm />} />

                      <Route path={'' + Pages.TaskSwitches + '/*'} element={<TaskSwitchPage />} />
                      <Route path={'' + Pages.TaskSwitches + '/:id'} element={<TaskSwitchForm />} />

                      <Route path={'' + Pages.CohortSessions + '/*'} element={<CohortMeetingsPage />} />
                      <Route path={'' + Pages.CohortSessions + '/:id'} element={<CohortMeetingForm />} />

                      <Route path={'' + Pages.Users + '/*'} element={<UsersPage />} />
                      <Route path={'' + Pages.Users + '/:id'} element={<UserForm />} />
                      <Route path={'' + Pages.Users + '/:id/' + Pages.Journey} element={<UserJourney />} />
                      <Route path={'' + Pages.Users + '/:id/' + Pages.Science} element={<UserScience />} />
                      <Route path={'' + Pages.Users + '/:id/' + Pages.UserRewards} element={<UserRewards />} />

                      <Route path={'' + Pages.AccessCode + '/*'} element={<AccessCodesPage />} />
                      <Route path={'' + Pages.AccessCode + '/:id'} element={<AccessCodeForm />} />

                      <Route path={`${Pages.Incentives}`} element={<IncentivesPage />} />
                      <Route path={`${Pages.Incentives}/:id`} element={<IncentivesForm />} />

                      <Route path={`${Pages.Notifications}`} element={<NotificationsPage />} />
                      <Route path={`${Pages.Notifications}/:id`} element={<NotificationsForm />} />

                      <Route path={`${Pages.FunctionRuns}`} element={<FunctionRunsPage />} />
                      <Route path={`${Pages.FunctionRuns}/:id`} element={<FunctionRunDetails />} />
                      <Route
                        path={'' + Pages.Cohorts + '/reportOnAll'}
                        element={
                          <InsightsContextProvider>
                            <AllCohortReportPage />
                          </InsightsContextProvider>
                        }
                      />
                    </>
                  )}
                  {role && ['admin', 'audio'].includes(role) && (
                    <>
                      <Route path={'/' + Pages.Unit + '/*'} element={<UnitPage />} />
                      <Route path={'/' + Pages.Unit + '/:id'} element={<UnitForm />} />

                      <Route path={'/' + Pages.Lessons + '/*'} element={<LessonsPage />} />
                      <Route path={'/' + Pages.Lessons + '/import'} element={<LessonImportPage />} />
                      <Route path={'/' + Pages.Lessons + '/:id'} element={<LessonForm />} />
                      <Route
                        path={`/${Pages.Lessons}/:id/${Pages.Chapter}/:chapterId&:lessonId`}
                        element={<ChapterForm />}
                      />
                      <Route
                        path={`/${Pages.Lessons}/:id/${Pages.AudioNode}/:audioNodeId/:chapterId`}
                        element={<AudioNodesFormPage />}
                      />

                      <Route path={`/${Pages.LessonAudioChunks}`} element={<LessonAudioChunksPage />} />
                      <Route path={`/${Pages.LessonAudioChunks}/:id`} element={<LessonAudioChunksForm />} />
                      <Route path={`/${Pages.LessonAudioChunks}/:id/:audioNodeId`} element={<AudioNodesFormPage />} />

                      {/* <Route path={`/${Pages.LessonAudioPractice}`} element={<LessonAudioPracticePage />} /> */}
                      {/* <Route path={`/${Pages.LessonAudioPractice}/:id`} element={<LessonAudioPracticeForm />} /> */}
                      <Route path={`/${Pages.LessonAudioPractice}/:id/:audioNodeId`} element={<AudioNodesFormPage />} />
                    </>
                  )}

                  {role && ['admin', 'science'].includes(role) && (
                    <>
                      <Route path={`/${Pages.DataScience}`} element={<DataSciencePage />} />
                    </>
                  )}

                  {role && ['admin', 'coach', 'science'].includes(role) && (
                    <>
                      <Route path={'' + Pages.Cohorts + '/*'} element={<CohortsPage />} />
                      <Route path={'' + Pages.Cohorts + '/dashboard/:id'} element={<CohortDashboardPage />} />
                      <Route path={'' + Pages.Cohorts + '/science/:id'} element={<CohortSciencePage />} />
                      <Route
                        path={'' + Pages.Cohorts + '/report/:id'}
                        element={
                          <InsightsContextProvider>
                            <CohortReportPage />
                          </InsightsContextProvider>
                        }
                      />
                    </>
                  )}

                  <Route path={'/rawData/:respirationId?/:sessionId'} element={<RawData />} />
                  <Route path={'/'} element={<HomePage />} />
                  <Route path={'/'} element={<HomePage />} />
                  <Route path={'/*'} element={<Navigate to={'/'} />} />
                </Routes>
              </div>
            </div>
          </main>
        </div>
      </div>
      {validateElement ? <ValidateElementModal /> : null}
    </>
  );
};
export default Sidebar;
