// *******************************************************
// Cohort Dashboard Page
// -------------------------------------------------------
// This is a Screen for showing list of Incentives
// -------------------------------------------
// *******************************************
// Module Imports
// -------------------------------------------
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon, CheckIcon, XIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';
import { format, startOfToday } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

// *******************************************
// Component Imports
// -------------------------------------------
// *******************************************
// Hooks Import
// -------------------------------------------
// *******************************************
// Action Imports
// -------------------------------------------
import { getUserDetailsByIds, getUserIdsByCohortId } from '../../collections/user';
import {
  DetailedStats,
  prepareDailyDiaries,
  prepareInAppSurveys,
  prepareOverview,
  prepareSkills,
  prepareStatistics,
  ShortStats,
} from './dashboardsStats';
import { getCohortById } from '../../collections/cohort';
import { getSessionsForUserIdArray } from '../../collections/session';
import { getLessonRatingsByCohort } from '../../collections/lessonRating';
import { AfterSessionType, getAfterSessionsWithRef } from '../../collections/afterSessionSurvey';
import { getDailyDiaryForArray } from '../../collections/dailyDiary';
import { formatMoney } from '../../collections/incentive';

// *******************************************
// Styles Imports
// -------------------------------------------
// *******************************************
// Constants
// -------------------------------------------
// *******************************************
// Types
// -------------------------------------------
// import { ExperienceTs, GoalTs } from 'src/enums/goals';

import { updateAccount } from '../../collections/account';
import { sendUserInvitationURL } from '../../collections/mail';
import { useAppContext } from '../../contexts/appContext';
import { emptyCallback } from '../../utility/GeneralUtilities';
import { CohortId, UserId } from '../../modeltypes/id';
import { UserDetailsType } from '../../modeltypes/composed';
import { SessionType } from '../../modeltypes/session';
import { LessonRatingType } from '../../modeltypes/lessonRating';
import { CohortType } from '../../modeltypes/cohort';
import { DailyDiaryType } from '../../modeltypes/dailyDiary';
import { LessonType } from '../../modeltypes/lesson';
import { TrackType } from '../../modeltypes/tracks';
import { getLessonsByIdArray } from '../../collections/lesson';
import { getTracksByIdArray } from '../../collections/tracks';
import { TaskType } from '../../modeltypes/tasks';
import { getTasksByIdArray } from '../../collections/tasks';
import { CSVDownload } from 'react-csv';
import { toast } from 'react-toastify';
import { prepareCSV, ResearchCSVType } from '../../utility/csvUtility';
import JSZip from 'jszip';
import { getBlob, getMetadata } from 'firebase/storage';
import { getStorageRef } from '../../firebase/utils';
import { saveAs } from 'file-saver';
import { getLessonAudioChunksByIdArray } from '../../collections/lessonAudioChunk';
import { getChallengesByIdArray } from '../../collections/challenges';

const allowUserInvitations = async ({ userId, email }: { userId?: UserId; email?: string }) => {
  if (userId && email) {
    await updateAccount(userId, { allowedInvites: 3 });
    await sendUserInvitationURL({
      email: email,
      uid: userId,
    });
  }
};

export type CustomerFirebaseMetadata = {
  lessonId?: string;
  sessionId?: string;
  unitId?: string;
  unitTrackId?: string;
  userId?: string;
};

const CohortDashboardPage = () => {
  const { id } = useParams<{ id: CohortId }>();
  const [cohort, setCohort] = useState<CohortType>();
  const [usersIds, setUsersIds] = useState<UserId[]>();
  const [userDetails, setUserDetails] = useState<UserDetailsType[]>();
  const [ratings, setRatings] = useState<LessonRatingType[]>();
  const [sessions, setSessions] = useState<SessionType[] | null>(null);
  const [overview, setOverview] = useState<ShortStats[]>();
  const [statistics, setStatistics] = useState<DetailedStats[]>();
  const [inAppSurveys, setInAppSurvey] = useState<DetailedStats[]>();
  const [dailyDiariesStats, setDailyDiariesStats] = useState<DetailedStats[]>();
  const [goalStats, setGoalStats] = useState<ShortStats[]>();
  const [experienceStats, setExperienceStats] = useState<ShortStats[]>();
  const [skills, setSkills] = useState<DetailedStats[]>();
  console.log('dailyDiariesStats: ', dailyDiariesStats);
  const [afterSessions, setAfterSessions] = useState<AfterSessionType[] | null>(null);
  const [beforeCCE, setBeforeCCE] = useState<AfterSessionType[] | null>(null);

  const [skillsBeforeLesson, setSkillsBeforeLesson] = useState<DetailedStats[] | null>(null);

  const [dailyDiaries, setDailyDiaries] = useState<DailyDiaryType[]>();
  const [cohortMoneyType, setCohortMoneyType] = useState<'DOLLAR' | 'CREDIT' | 'NONE'>('NONE');

  const [nodes, setNodes] = useState<LessonType[] | null>(null);
  const [tracks, setTracks] = useState<TrackType[] | null>(null);
  const [tasks, setTasks] = useState<TaskType[] | null>(null);

  const [isDownloading, setIsDownloading] = useState(false);
  const [csvData, setCsvData] = useState<null | ResearchCSVType[]>(null);

  const [isPreparingSensorsData, setIsPreparingSensorsData] = useState(false);

  const { setBreadcrumbPaths } = useAppContext();

  const showSendInvites = false;
  const addItems = (prev?: number | null, next?: number | null) => (prev || 0) + (next || 0);

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Cohorts',
        path: '/cohorts',
      },
      {
        label: 'Dashboard',
        path: `/cohorts/dashboard/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  useEffect(() => {
    if (id) {
      getCohortById(id).then((cohortDB) => {
        if (cohortDB) {
          setCohort(cohortDB);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (cohort && cohort.id) {
      console.log(cohort);
      getUserIdsByCohortId(cohort.id, cohort?.isTestCohort).then((ids) => {
        setUsersIds(ids);
        setOverview(
          prepareOverview({
            users: ids.length,
            started: cohort.creationDate?.toDate(),
            ends: cohort.endTime?.toDate(),
          }),
        );
      });
      getLessonRatingsByCohort(cohort.id).then((lessonRatings) => {
        setRatings(lessonRatings);
      });
    }
  }, [cohort]);

  useEffect(() => {
    if (usersIds && usersIds.length > 0 && cohort && cohort.creationDate) {
      getSessionsForUserIdArray(usersIds, cohort.creationDate).then((sessionsDB) => {
        setSessions(sessionsDB.flat());
      });

      getDailyDiaryForArray(usersIds).then((dds) => setDailyDiaries(dds.flat()));
    }
    if (usersIds) {
      getUserDetailsByIds(usersIds).then((usersDetailsDb) => {
        setCohortMoneyType((prev) => usersDetailsDb?.at(0)?.incentives?.moneyType || prev);
        setUserDetails(
          usersDetailsDb.sort((user, anotherUser) => user.user.lastName.localeCompare(anotherUser.user.lastName)),
        );
        const contentIds = usersDetailsDb
          .map(({ user }) => ({
            track: user.currentLesson?.trackId,
            nodeId: user.currentLesson?.nodeId,
            // type: user.currentLesson?.type,
          }))
          .reduce(
            (object: { tracks: string[]; units: string[]; nodes: string[] }, el) => {
              if (el.track) object.tracks.push(el.track);
              if (el.nodeId) object.nodes.push(el.nodeId);

              return object;
            },
            {
              tracks: [],
              units: [],
              nodes: [],
            },
          );

        getLessonsByIdArray([...new Set(contentIds.nodes)]).then((fNodes) => setNodes([...(nodes || []), ...fNodes]));
        getTasksByIdArray([...new Set(contentIds.nodes)]).then((fNodes) => setTasks(fNodes));
        getLessonAudioChunksByIdArray([...new Set(contentIds.nodes)]).then((chunks) =>
          setNodes([...(nodes || []), ...chunks]),
        );
        getChallengesByIdArray([...new Set(contentIds.nodes)]).then((challenges) =>
          setNodes([...(nodes || []), ...challenges]),
        );

        getTracksByIdArray([...new Set(contentIds.tracks)]).then((fTracks) => setTracks(fTracks));
      });
    }
  }, [usersIds, cohort]);

  useEffect(() => {
    if (ratings && sessions) {
      setStatistics(
        prepareStatistics({
          lessonCompleteNow: sessions.filter((session) => session.lessonFinished).length,
          lessonCompletedBefore: sessions.filter(
            (session) => session.lessonFinished && session.date.toDate() < startOfToday(),
          ).length,
          totalSessionsNow: sessions.length,
          sessionsBefore: sessions.filter((session) => session.date.toDate() < startOfToday()).length,
          ratings: ratings.map(({ rate }) => rate).reduce(addItems, 0) / ratings.length || 0,
        }),
      );
    }
  }, [ratings, sessions]);

  useEffect(() => {
    if (dailyDiaries && afterSessions) {
      setInAppSurvey(
        prepareInAppSurveys({
          afterSessionNow: afterSessions.length,
          afterSessionBefore: afterSessions.filter(
            (session) => session.showTime && session.showTime.toDate() < startOfToday(),
          ).length,
          dailyDiaryNow: dailyDiaries.length,
          dailyDiaryBefore: dailyDiaries.filter(
            (session) => session.showTime && session.showTime.toDate() < startOfToday(),
          ).length,
        }),
      );
    }
  }, [dailyDiaries, afterSessions]);

  useEffect(() => {
    if (isDownloading) {
      toast.info('Preparing CSV data', { toastId: 'csv' });

      const csvData = prepareCSV({
        afterSessions,
        beforeCCE,
        sessions,
        users: userDetails?.map((el) => el.account) || null,
        timezone: cohort?.timezone?.name,
        isObfuscated: cohort?.isObfuscated,
      });
      if (csvData) {
        toast.update('csv', {
          type: 'success',
          render: 'Downloading...',
        });
        setCsvData(csvData);
      } else {
        toast.update('csv', {
          type: 'error',
          render: 'Not enough data to prepare CSV!',
        });
      }

      setIsDownloading(false);
    }
  }, [isDownloading, userDetails]);

  type GoalStats = {
    goal: string;
    count: number;
  };

  type ExperStats = {
    experience: string;
    count: number;
  };

  useEffect(() => {
    if (userDetails) {
      // console.log('userDetails: ', userDetails);

      const tempGoalStats: Record<string, GoalStats> = {};
      const tempExperienceStats: Record<string, ExperStats> = {};

      for (const { user } of userDetails) {
        const experience: string | [key: string] = user?.experience || 'NotKnow';
        const goal: string | [key: string] = user?.goal || 'NotKnow';

        tempExperienceStats[experience] = {
          experience: experience,
          count: tempExperienceStats?.[experience] ? tempExperienceStats[experience].count + 1 : 1,
        };

        tempGoalStats[goal] = {
          goal: goal,
          count: tempGoalStats[goal] ? tempGoalStats[goal].count + 1 : 1,
        };
      }

      console.log('tempGoalStats: ', tempGoalStats);
      console.log('tempExperienceStats: ', tempExperienceStats);

      const goalS: ShortStats[] = Object.values(tempGoalStats).map((x) => {
        const obj = {
          name: `${x.goal}`,
          stat: x.count,
        };
        return obj;
      });

      const experienceS: ShortStats[] = Object.values(tempExperienceStats).map((x) => {
        const obj: ShortStats = {
          name: `${x.experience}`,
          stat: x.count,
        };
        return obj;
      });
      setGoalStats(goalS || []);
      setExperienceStats(experienceS || []);
    }
  }, [userDetails]);

  useEffect(() => {
    // noinspection DuplicatedCode
    const prepareSurveysData = async () => {
      if (sessions) {
        const afterSessionsRefs = sessions
          .filter((session) => session.afterSessionSurvey)
          .map((session) => session.afterSessionSurvey);

        const beforeCCE = sessions.filter((session) => session.beforeCCE).map((session) => session.beforeCCE);

        const afterSessions = await Promise.all(
          afterSessionsRefs.map(async (ref) => getAfterSessionsWithRef(ref)),
        ).then((asArray) => asArray.filter((el): el is AfterSessionType => !!el?.id));

        const bCCE = await Promise.all(beforeCCE.map(async (ref) => getAfterSessionsWithRef(ref))).then((bcceArray) =>
          bcceArray.filter((el): el is AfterSessionType => !!el?.id),
        );

        return {
          afterSessions,
          bCCE,
        };
      }
      return Promise.resolve({
        afterSessions: [],
        bCCE: [],
      });
    };
    prepareSurveysData().then((surveysData) => {
      setAfterSessions(surveysData.afterSessions);
      setBeforeCCE(surveysData.bCCE);
    });
  }, [sessions]);

  useEffect(() => {
    if (afterSessions && afterSessions.length > 0) {
      setSkills(prepareSkills(afterSessions));
    }

    if (beforeCCE && beforeCCE.length > 0) {
      setSkillsBeforeLesson(prepareSkills(beforeCCE));
    }

    if (dailyDiaries && dailyDiaries.length > 0) {
      setDailyDiariesStats(prepareDailyDiaries(dailyDiaries));
    }
  }, [afterSessions, dailyDiaries]);

  const downloadZippedSensorsData = async () => {
    setIsPreparingSensorsData(true);
    if (sessions) {
      toast.info('Preparing sensors files to be downloaded', {
        toastId: 'sensors',
        autoClose: false,
        closeOnClick: false,
        isLoading: true,
        progress: 0,
      });
      const zip = new JSZip();

      const csvData = prepareCSV({
        afterSessions,
        beforeCCE,
        sessions,
        users: userDetails?.map((el) => el.account) || null,
        timezone: cohort?.timezone?.name,
        isObfuscated: cohort?.isObfuscated,
      });

      for (const idx in sessions) {
        const index = +idx;

        toast.update('sensors', { progress: (index + 1) / sessions.length });
        const session = sessions[index];
        const audioRef = session.audioFile ? getStorageRef(session.audioFile) : null;
        const accelerometerRef = session.accelFile ? getStorageRef(session.accelFile) : null;
        const gyroscopeRef = session.gyroFile ? getStorageRef(session.gyroFile) : null;
        const date = format(session.sessionStart.toDate(), 'MM-dd-yyyy@hh_mm');
        const sessionName = session?.nameOfSession?.replace(' ', '');
        const timeInLesson = session?.timeInLesson ? (session?.timeInLesson / 60).toFixed(1) : '0';

        if (audioRef) {
          const audioFile = await getBlob(audioRef);
          const metadata = await getMetadata(audioRef);
          console.log('Audio metadata: ', metadata);
          zip.file(`audioData/${session.id}-${audioRef.name}`, audioFile, {
            comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
          });
        } else {
          console.log(`session ${session.id}, doesnt have audio data`);
        }
        if (accelerometerRef) {
          const accelFile = await getBlob(accelerometerRef);
          const metadata = await getMetadata(accelerometerRef);
          console.log('accelFile metadata: ', metadata);
          const customMetadata: CustomerFirebaseMetadata | undefined = metadata?.customMetadata;

          const accelString = await accelFile.text();
          const accelData = JSON.parse(accelString);
          const csvSession = csvData?.find((el) => el.sessionId === session.id) || {};
          const fullData = {
            csv: csvSession,
            accelerometer: accelData,
          };

          const fileName = customMetadata?.lessonId
            ? `accelerometer/lessonId-${customMetadata?.lessonId}_duration-${timeInLesson}min_tackId-${customMetadata?.unitTrackId}_userId-${customMetadata?.userId}_date-${date}_${accelerometerRef.name}`
            : `accelerometer/lesson-${sessionName}_duration-${timeInLesson}min_unitId-${session?.unitId}_userId-${session?.uid}_date-${date}_${accelerometerRef.name}`;

          console.log('fileName', fileName);

          const fileWithCsv = new Blob([JSON.stringify(fullData, null, 20)]);
          zip.file(fileName, fileWithCsv, {
            comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
          });
        } else {
          console.log(`session ${session.id}, doesnt have accel data`);
        }

        if (gyroscopeRef) {
          const gyroFile = await getBlob(gyroscopeRef);
          const metadata = await getMetadata(gyroscopeRef);
          console.log('gyroFile metadata: ', metadata);

          const gyroString = await gyroFile.text();
          const gyroData = JSON.parse(gyroString);
          const csvSession = csvData?.find((el) => el.sessionId === session.id) || {};
          const fullData = {
            csv: csvSession,
            gyroscope: gyroData,
          };

          const fileWithCsv = new Blob([JSON.stringify(fullData, null, 20)]);
          const customMetadata: CustomerFirebaseMetadata | undefined = metadata?.customMetadata;

          const fileName = customMetadata?.lessonId
            ? `gyroscope/lessonId-${customMetadata?.lessonId}_duration-${timeInLesson}min_tackId-${customMetadata?.unitTrackId}_userId-${customMetadata?.userId}_date-${date}_${gyroscopeRef.name}`
            : `gyroscope/lesson-${sessionName}_duration-${timeInLesson}min_unitId-${session?.unitId}_userId-${session?.uid}_date-${date}_${gyroscopeRef.name}`;
          console.log('fileName', fileName);

          zip.file(fileName, fileWithCsv, {
            comment: `sessionId: ${session.id}, userId: ${session.uid}, sessionName: ${session.nameOfSession}`,
          });
        } else {
          console.log(`session ${session.id}, doesnt have gyro data`);
        }
      }

      if (Object.values(zip.files).length > 0) {
        toast.info('Zipping files...', {
          toastId: 'zipping',
          isLoading: true,
          closeOnClick: false,
        });
        zip
          .generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
              /* compression level ranges from 1 (best speed) to 9 (best compression) */
              level: 9,
            },
          })
          .then(function (blob) {
            saveAs(blob, `${cohort?.name}-${new Date().toISOString().replace('T', '/').split('.')[0]}`);
            toast.update('zipping', {
              type: 'success',
              render: 'Downloaded zipped sensors data...',
              closeOnClick: true,
              isLoading: false,
              autoClose: 5000,
            });
          });
      } else {
        toast.update('zipping', {
          type: 'error',
          render: 'There is no sensors files to be downloaded',
          closeOnClick: true,
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
    setIsPreparingSensorsData(false);
  };

  const detailedRows: { name: string; dataArray?: DetailedStats[]; additionalData?: DetailedStats[] | null }[] = [
    {
      name: 'Statistics',
      dataArray: statistics,
    },
    {
      name: 'In App Surveys',
      dataArray: inAppSurveys,
    },
    {
      name: 'Daily Diaries',
      dataArray: dailyDiariesStats,
    },
    {
      name: 'Skills',
      dataArray: skills,
      additionalData: skillsBeforeLesson,
    },
  ];

  const overviewRows: { name: string; dataArray?: ShortStats[] }[] = [
    {
      name: 'Overview',
      dataArray: overview,
    },
    {
      name: 'Goals',
      dataArray: goalStats,
    },
    {
      name: 'Experience',
      dataArray: experienceStats,
    },
  ];

  const prepareTotalIncentives = () =>
    userDetails && userDetails.length > 0
      ? formatMoney(
          userDetails
            .filter((person) => person.incentives)
            .map((person) =>
              !person.incentives
                ? 0
                : person.incentives.stages
                    .filter(({ finished }) => finished)
                    .map(({ amount }) => amount)
                    .reduce(addItems, 0),
            )
            .reduce(addItems, 0),
          cohortMoneyType,
        )
      : 0;

  const prepareTotalTime = (): number =>
    userDetails && userDetails.length > 0
      ? userDetails
          .map(
            ({ calendar }) =>
              calendar
                ?.map(({ value }) => value?.meditationTimes?.map(({ time }) => time).reduce(addItems, 0))
                ?.reduce(addItems, 0) || 0,
          )
          .reduce(addItems, 0) / 60
      : 0;

  const printCurrentLesson = (person: UserDetailsType) => {
    const userJourney = person.userJourney;
    const currentLesson = person.user.currentLesson;
    const currentUnit = userJourney?.journey.find((unit) => currentLesson?.unitId === unit.id);

    if (currentLesson?.nodeId === 'routing') {
      return <div className={'text-center'}>Finished track</div>;
    }

    if (!userJourney || !currentUnit) {
      return (
        <div className={'text-center'}>
          <p>-</p>
        </div>
      );
    }

    const track = tracks?.find((el) => el.id === currentLesson?.trackId || el.id === currentUnit.trackId);
    const lesson =
      nodes?.find((el) => el.id === currentLesson?.nodeId) || tasks?.find((el) => el.id === currentLesson?.nodeId);
    const allElements = userJourney?.journey.flatMap((unit) => unit.elements);

    return (
      <div className={'flex flex-row whitespace-pre-line text-center justify-center items-center'}>
        <p className={'w-[30%] '}>{track?.title}</p>
        <p className={'w-[30%] '}>{currentUnit.title}</p>
        <p className={'w-[30%] '}>{lesson?.title}</p>
        <p className={'w-[10%] '}>{(allElements?.findIndex((el) => el.id === currentLesson?.nodeId) || 0) + 1}</p>
      </div>
    );
  };

  return (
    <div>
      <div className={'mb-10 text-center flex-col items-center flex'}>
        <h3 className='text-3xl leading-6 font-medium text-gray-900  '>{cohort?.name}</h3>
        {cohort?.config && userDetails ? (
          <div className='flex flex-row gap-x-7 self-center'>
            <button
              disabled={isDownloading}
              className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold'
              onClick={() => setIsDownloading(true)}
            >
              Download CSV
            </button>
            <button
              disabled={isPreparingSensorsData}
              className='mt-4 text-indigo-400 hover:text-indigo-700 font-bold'
              onClick={() => downloadZippedSensorsData()}
            >
              Download Sensors data
            </button>
          </div>
        ) : null}
        {csvData && (
          <CSVDownload
            data={csvData}
            asyncOnClick
            onClick={(x, done) => {
              console.log('x');
              done(false);
            }}
          />
        )}
      </div>
      {overviewRows.map(({ dataArray, name }) => (
        <div key={name} className='bg-stone-100 p-5 rounded-xl mb-5'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>{name}</h3>
          <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
            {dataArray?.map((item) => (
              <div key={item.name} className='px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6'>
                <dt className='text-sm font-medium text-gray-500 truncate'>{item.name}</dt>
                <dd className='mt-1 text-2xl font-semibold text-gray-900'>{item.stat}</dd>
              </div>
            ))}
          </dl>
        </div>
      ))}
      {detailedRows.map(({ dataArray, name, additionalData }) => (
        <div key={name} className='bg-stone-100 p-5 rounded-xl mb-5'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>{name}</h3>
          <dl
            className={`mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-${
              dataArray?.length || 1
            } md:divide-y-0 md:divide-x`}
          >
            {dataArray?.map((item) => {
              const additionalItem = additionalData ? additionalData.find((el) => el.name === item.name) : null;
              return (
                <div key={item.name} className='px-4 py-5 sm:p-6'>
                  <dt className='text-base font-normal text-gray-900'>{item.name}</dt>
                  {additionalItem ? (
                    <div className={'flex flex-row items-center'}>
                      <p className='mr-2'>Before lesson:</p>
                      <div className='flex items-baseline text-2xl font-semibold text-sky-600'>
                        {additionalItem.stat}
                        {!additionalItem?.previousStat &&
                          additionalItem.previousStat !== null &&
                          additionalItem.previousStat !== additionalItem.stat && (
                            <span className='ml-2 text-sm font-medium text-gray-500'>
                              from {additionalItem.previousStat}
                            </span>
                          )}
                      </div>
                    </div>
                  ) : null}
                  <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                    <div className={'flex flex-row items-center'}>
                      {additionalItem ? <p className='mr-2'>After lesson:</p> : null}

                      <div className='flex items-baseline text-2xl font-semibold text-sky-600'>
                        {item.stat}
                        {item?.previousStat && item.previousStat !== null && item.previousStat !== item.stat && (
                          <span className='ml-2 text-sm font-medium text-gray-500'>from {item.previousStat}</span>
                        )}
                      </div>
                    </div>
                    {item.previousStat !== null && item.changeType !== 'same' && (
                      <div
                        className={`
                        ${
                          item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                        } inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0`}
                      >
                        {item.changeType === 'increase' ? (
                          <ArrowSmUpIcon
                            className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500'
                            aria-hidden='true'
                          />
                        ) : item?.previousStat ? (
                          <ArrowSmDownIcon
                            className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500'
                            aria-hidden='true'
                          />
                        ) : null}
                        <span className='sr-only'>{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                        {item.change}
                      </div>
                    )}
                  </dd>
                </div>
              );
            })}
          </dl>
        </div>
      ))}
      <div className='mt-10 '>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-xl font-semibold text-gray-900'>User Accountability</h1>
          </div>
        </div>
        <div className='mt-8 flex flex-col px-4 sm:px-6 lg:px-8 mb-10'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-center'
                      >
                        Name
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        Email
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left min-w-[375px] text-sm font-semibold text-gray-900 text-center'
                      >
                        Current Lesson
                        <div className='flex flex-row mt-3'>
                          <p className={'w-[30%]'}>Track</p>
                          <p className={'w-[30%]  '}>Unit</p>
                          <p className={'w-[30%]  '}>Lesson</p>
                          <p className={'w-[10%]  '}># </p>
                        </div>
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        Sessions
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        Total time in app [mins]
                      </th>
                      <th
                        scope='col'
                        colSpan={2}
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'
                      >
                        Last logged on
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        App version
                      </th>
                      {/* <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        Send invites
                      </th> */}
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        Goal
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        Experience
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {userDetails
                      ? userDetails?.map((person, index) => (
                          <tr key={person.account.email} className={'hover:bg-stone-50'}>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-center'>
                              <div
                                className='font-bold'
                                onClick={() => null}
                              >{`${person.user.firstName} ${person.user.lastName}`}</div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>{person.account.email}</div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>{printCurrentLesson(person)}</div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>{`${
                                sessions?.filter((session) => session.uid === person.account.uid).length || '-'
                              }`}</div>
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>
                                {`${
                                  Math.round(
                                    person.calendar
                                      .map(
                                        ({ value }) =>
                                          value.meditationTimes?.map(({ time }) => time).reduce(addItems, 0) || 0,
                                      )
                                      .reduce(addItems, 0) / 60,
                                  ) || '-'
                                }`}
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>
                                {person.lastLoginDate ? format(person.lastLoginDate, 'hh:mm aaaa') : '-'}
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>
                                {person.lastLoginDate ? format(person.lastLoginDate, 'd MMMM, EEEE') : '-'}
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>{person.account.appVersion}</div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>{person?.user?.goal}</div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                              <div className='text-gray-900'>{person?.user?.experience}</div>
                            </td>

                            {!showSendInvites ? null : (
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                                <button
                                  onClick={() => {
                                    const temp = [...userDetails];
                                    temp[index].account.allowedInvites = 3;
                                    setUserDetails(temp);
                                    allowUserInvitations({
                                      userId: person.account.uid,
                                      email: person.account.email,
                                    }).then(emptyCallback);
                                  }}
                                  disabled={person.account.allowedInvites !== undefined}
                                  type='button'
                                  className={`${
                                    person.account.allowedInvites === undefined ? 'hover:bg-sky-700' : 'opacity-25'
                                  } inline-flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:w-auto`}
                                >
                                  Send
                                </button>
                              </td>
                            )}
                          </tr>
                        ))
                      : null}
                    <tr className='bg-stone-100'>
                      <th
                        scope='col'
                        colSpan={2}
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-center'
                      >
                        Total
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        {sessions?.filter(({ lessonFinished }) => lessonFinished)?.length || 0}
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        {sessions?.length || 0}
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        {prepareTotalIncentives()}
                      </th>
                      <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                        {prepareTotalTime().toFixed(0)}
                      </th>
                      <th scope='col' colSpan={3} className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                        <span className='sr-only'>empty</span>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='sm:flex sm:items-center mb-8'>
          <div className='sm:flex-auto'>
            <h1 className='text-xl font-semibold text-gray-900'>Session Feed</h1>
          </div>
        </div>
        <div
          style={{
            maxHeight: 500,
            overflow: 'scroll',
          }}
        >
          <ul role='list' className='-mb-8 px-4 sm:px-6 lg:px-8 mb-10'>
            {sessions && userDetails ? (
              sessions
                .map((session) => format(session.sessionStart.toDate(), 'dd EE MMMM'))
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((section) => {
                  const filteredSessions = sessions.filter(
                    (s) => format(s.sessionStart.toDate(), 'dd EE MMMM') === section,
                  );
                  return (
                    <div key={section}>
                      <h1 className='text-xl font-semibold text-gray-900 mb-5'>{section}</h1>
                      {filteredSessions.map((session, eventIdx) => {
                        const user = userDetails.find(({ account }) => account.uid === session.uid);

                        return (
                          <li key={session.id}>
                            <div className='relative pb-8'>
                              {eventIdx !== filteredSessions.length - 1 ? (
                                <span
                                  className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
                                  aria-hidden='true'
                                />
                              ) : null}
                              <div className='relative flex space-x-3'>
                                <div>
                                  <span
                                    className={`${
                                      session.lessonFinished ? 'bg-green-500' : 'bg-red-500'
                                    } h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`}
                                  >
                                    {session.lessonFinished ? (
                                      <CheckIcon className='h-5 w-5 text-white' aria-hidden='true' />
                                    ) : (
                                      <XIcon className='h-5 w-5 text-white' aria-hidden='true' />
                                    )}
                                  </span>
                                </div>
                                <div className='min-w-0 flex-1 flex justify-between space-x-4 self-center'>
                                  <div>
                                    <p className='text-sm text-gray-500'>
                                      <b>{`${user?.user.firstName} ${user?.user.lastName}`}</b>
                                      {` ${session.lessonFinished ? 'finished' : 'started'} ${session.nameOfSession}`}
                                    </p>
                                  </div>
                                  <div className='text-right text-sm whitespace-nowrap text-gray-500'>
                                    <time dateTime={session.sessionStart.toDate().toString()}>
                                      {format(session.sessionStart.toDate(), 'hh:mm, dd.MM.yyyy')}
                                    </time>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </div>
                  );
                })
            ) : (
              <Skeleton count={5} />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CohortDashboardPage;
