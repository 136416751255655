// This file is meant to 'wall off' the model types and schemas from
// having to import firebase/firestore directly. We should deliberately
// move functionality here, even if we're just re-exporting an FB/FS
// type for the time being. It will give us a good place to start if we
// ever need to change data abstractions. It might also help us deal with
// the Firebase v8 vs Firebase v9 issues.
import { firestore } from '../firebase/firebaseConfig';
import {
  collection as fsCollection,
  doc as fsDoc,
  DocumentReference,
  runTransaction as _runTransaction,
  writeBatch as fsWriteBatch,
} from 'firebase/firestore';
import { CollectionReference, Transaction, WriteBatch } from '@firebase/firestore';
import { getNewIdFor } from '../collections/shared';

import {
  AccessCodeId,
  AccessId,
  AccessRequestId,
  AdminPageId,
  AfterSessionSurveyId,
  AudioNodeId,
  AudioRecordingWhitelistId,
  ChapterId,
  CoachId,
  CohortId,
  CohortMeetingId,
  CommunicationId,
  CompanyId,
  DailyDiaryId,
  IncentiveTemplateId,
  LessonHomeworkId,
  LessonId,
  LessonProgressTemplateId,
  LessonRatingId,
  MailId,
  NotificationTemplateId,
  ProgramId,
  RemoteConfigId,
  ScienceDataId,
  SessionId,
  StatisticsDataId,
  SurveyId,
  UserId,
} from '../modeltypes/id';

import {
  ACCESS_CODE_TABLE_NAME,
  ACCESS_REQUEST_TABLE_NAME,
  ACCESS_TABLE_NAME,
  ADMIN_PAGE_TABLE_NAME,
  AFTER_SESSION_SURVEY_TABLE_NAME,
  AUDIO_NODE_TABLE_NAME,
  AUDIO_RECORDING_WHITELIST_TABLE_NAME,
  CHAPTER_TABLE_NAME,
  COACH_TABLE_NAME,
  COHORT_MEETING_TABLE_NAME,
  COHORT_TABLE_NAME,
  COMMUNICATIONS_TABLE_NAME,
  COMPANY_TABLE_NAME,
  DAILY_DIARY_TABLE_NAME,
  INCENTIVE_TEMPLATE_TABLE_NAME,
  LESSON_HOMEWORK_TABLE_NAME,
  LESSON_PROGRESS_TEMPLATE_TABLE_NAME,
  LESSON_RATING_TABLE_NAME,
  LESSON_TABLE_NAME,
  MAIL_TABLE_NAME,
  NOTIFICATION_TEMPLATE_TABLE_NAME,
  PROGRAM_TABLE_NAME,
  REMOTE_CONFIG_TABLE_NAME,
  SCIENCE_DATA_TABLE_NAME,
  SESSIONS_TABLE_NAME,
  STATISTICS_DATA_TABLE_NAME,
  SURVEY_TABLE_NAME,
  USER_TABLE_NAME,
} from '../collections/tableName';

export const IdFactory = Object.freeze({
  Access: getNewIdFor<AccessId>(ACCESS_TABLE_NAME),
  AccessCode: getNewIdFor<AccessCodeId>(ACCESS_CODE_TABLE_NAME),
  AccessRequest: getNewIdFor<AccessRequestId>(ACCESS_REQUEST_TABLE_NAME),
  AdminPage: getNewIdFor<AdminPageId>(ADMIN_PAGE_TABLE_NAME),
  AfterSessionSurvey: getNewIdFor<AfterSessionSurveyId>(AFTER_SESSION_SURVEY_TABLE_NAME),
  AudioNode: getNewIdFor<AudioNodeId>(AUDIO_NODE_TABLE_NAME),
  AudioRecordingWhitelist: getNewIdFor<AudioRecordingWhitelistId>(AUDIO_RECORDING_WHITELIST_TABLE_NAME),
  Chapter: getNewIdFor<ChapterId>(CHAPTER_TABLE_NAME),
  Coach: getNewIdFor<CoachId>(COACH_TABLE_NAME),
  Cohort: getNewIdFor<CohortId>(COHORT_TABLE_NAME),
  CohortMeeting: getNewIdFor<CohortMeetingId>(COHORT_MEETING_TABLE_NAME),
  Company: getNewIdFor<CompanyId>(COMPANY_TABLE_NAME),
  Communication: getNewIdFor<CommunicationId>(COMMUNICATIONS_TABLE_NAME),
  DailyDiary: getNewIdFor<DailyDiaryId>(DAILY_DIARY_TABLE_NAME),
  IncentiveTemplate: getNewIdFor<IncentiveTemplateId>(INCENTIVE_TEMPLATE_TABLE_NAME),
  Lesson: getNewIdFor<LessonId>(LESSON_TABLE_NAME),
  LessonHomework: getNewIdFor<LessonHomeworkId>(LESSON_HOMEWORK_TABLE_NAME),
  LessonProgressTemplate: getNewIdFor<LessonProgressTemplateId>(LESSON_PROGRESS_TEMPLATE_TABLE_NAME),
  LessonRating: getNewIdFor<LessonRatingId>(LESSON_RATING_TABLE_NAME),
  NotificationTemplate: getNewIdFor<NotificationTemplateId>(NOTIFICATION_TEMPLATE_TABLE_NAME),
  Program: getNewIdFor<ProgramId>(PROGRAM_TABLE_NAME),
  RemoteConfig: getNewIdFor<RemoteConfigId>(REMOTE_CONFIG_TABLE_NAME),
  ScienceData: getNewIdFor<ScienceDataId>(SCIENCE_DATA_TABLE_NAME),
  Sessions: getNewIdFor<SessionId>(SESSIONS_TABLE_NAME),
  StatisticsData: getNewIdFor<StatisticsDataId>(STATISTICS_DATA_TABLE_NAME),
  Survey: getNewIdFor<SurveyId>(SURVEY_TABLE_NAME),
  User: getNewIdFor<UserId>(USER_TABLE_NAME),
  Mail: getNewIdFor<MailId>(MAIL_TABLE_NAME),

  AccessIdFromString: (s: string) => s as unknown as AccessId,
  AccessCodeIdFromString: (s: string) => s as unknown as AccessCodeId,
  AccessRequestIdFromString: (s: string) => s as unknown as AccessRequestId,
  AdminPageIdFromString: (s: string) => s as unknown as AdminPageId,
  AfterSessionSurveyIdFromString: (s: string) => s as unknown as AfterSessionSurveyId,
  AudioNodeIdFromString: (s: string) => s as unknown as AudioNodeId,
  AudioRecordingWhitelistIdFromString: (s: string) => s as unknown as AudioRecordingWhitelistId,
  ChapterIdFromString: (s: string) => s as unknown as ChapterId,
  CoachIdFromString: (s: string) => s as unknown as CoachId,
  CohortIdFromString: (s: string) => s as unknown as CohortId,
  CohortMeetingIdFromString: (s: string) => s as unknown as CohortMeetingId,
  CompanyIdFromString: (s: string) => s as unknown as CompanyId,
  DailyDiaryIdFromString: (s: string) => s as unknown as DailyDiaryId,
  IncentiveTemplateIdFromString: (s: string) => s as unknown as IncentiveTemplateId,
  LessonIdFromString: (s: string) => s as unknown as LessonId,
  LessonHomeworkIdFromString: (s: string) => s as unknown as LessonHomeworkId,
  LessonProgressTemplateIdFromString: (s: string) => s as unknown as LessonProgressTemplateId,
  LessonRatingIdFromString: (s: string) => s as unknown as LessonRatingId,
  NotificationTemplateIdFromString: (s: string) => s as unknown as NotificationTemplateId,
  ProgramIdFromString: (s: string) => s as unknown as ProgramId,
  RemoteConfigIdFromString: (s: string) => s as unknown as RemoteConfigId,
  ScienceDataIdFromString: (s: string) => s as unknown as ScienceDataId,
  SessionsIdFromString: (s: string) => s as unknown as SessionId,
  StatisticsDataIdFromString: (s: string) => s as unknown as StatisticsDataId,
  SurveyIdFromString: (s: string) => s as unknown as SurveyId,
  UserIdFromString: (s: string) => s as unknown as UserId,
  MailIdFromString: (s: string) => s as unknown as MailId,
});

export { Timestamp, QueryConstraint, FieldValue } from 'firebase/firestore';

export {
  addDoc,
  deleteDoc,
  deleteField,
  documentId,
  DocumentReference,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  Query,
  QuerySnapshot,
  updateDoc,
  where,
  FirestoreError,
  arrayRemove,
  arrayUnion,
  orderBy,
  increment,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';

export type { Unsubscribe } from 'firebase/firestore';

export function writeBatch(): WriteBatch {
  return fsWriteBatch(firestore);
}

export function collection(path: string, ...pathSegments: string[]): CollectionReference {
  return fsCollection(firestore, path, ...pathSegments);
}

export function doc(path: string, ...pathSegments: string[]): DocumentReference;
export function doc(reference: DocumentReference<unknown>, path: string, ...pathSegments: string[]): DocumentReference;
export function doc<T>(
  reference: CollectionReference<T>,
  path?: string,
  ...pathSegments: string[]
): DocumentReference<T>;

// This is kinda skeezy, but it achieves what I want it to: A `doc` re-export that proxies all the overloads of `doc`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function doc<T extends Array<any>, U>(...args: T): U {
  if (args.length > 0 && typeof args[0] === 'string') {
    // noinspection TypeScriptValidateTypes
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return fsDoc(firestore, ...args);
  } else {
    // noinspection TypeScriptValidateTypes
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return fsDoc(...args);
  }
}

export { getUserLastLoginDate } from '../firebase/firebaseConfig';

export { getObjectsForCollection } from '../firebase/utils';

export async function runTransaction<T>(updateFunction: (transaction: Transaction) => Promise<T>): Promise<T> {
  return _runTransaction(firestore, updateFunction);
}
